import React, {useRef, useState} from "react";
import * as emailjs from "@emailjs/browser";

const SERVICE_ID = "service_a5fvlr9";
const TEMPLATE_ID = "template_qgk825i";
const PUBLIC_KEY = "lAO21L1WE5TNx1q4H";

const Contact = () => {
  const form = useRef();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("test1234")

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
      .then((result) => {
          setMessage("Votre message a été envoyé avec succès !")
      }, (error) => {
          setError("Une erreur est survenue, veuillez réessayer.")
      });
  }

  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <h2>Contactez-nous</h2>
        <div className="row gy-4 contct">
          <form ref={form} onSubmit={sendEmail} className="email-form">
            <div className="row gy-4">
              <div className="col-md-6">
                <input
                  type="text"
                  name="user_name"
                  className="form-control"
                  placeholder="Votre nom"
                  required
                />
              </div>
              <div className="col-md-6 ">
                <input
                  type="email"
                  className="form-control"
                  name="user_email"
                  placeholder="Votre E-mail"
                  required
                />
              </div>
              {/*
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  placeholder="Sujet"
                  required
                />
              </div>
              */}
              <div className="col-md-12">
                <textarea
                  className="form-control"
                  name="message"
                  rows={6}
                  placeholder="Message"
                  required
                  defaultValue={""}
                />
              </div>
              <div className="col-md-12 text-center text-lg-end">
                <div className="loading">Loading</div>
                {error && (
                  <div className="error-message" >
                    {error}
                  </div>
                )}
                {message && (
                  <div className="sent-message" >
                    {message}
                  </div>
                )}
                <button type="submit">Envoyer</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
};

export default Contact;
