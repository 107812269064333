import React from "react";
import {Select} from "semantic-ui-react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

const NavBar = (props) => {
  const options = [
    { key: 'en', text: 'en', value: 'en' },
    { key: 'fr', text: 'fr', value: 'fr' },
  ]

  return (
    <nav id="navbar" className="navbar">
      <ul>
        <li className="dropdown">
          <a href="/">
            <span><FormattedMessage id="nav.presentation"/></span> <i className="bi bi-chevron-down"/>
          </a>
          <ul>
            <li>
              <a className="nav-link scrollto" href="/#about">
                <FormattedMessage id="nav.about"/>
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="/#objectifs">
                <FormattedMessage id="nav.goals"/>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a className="nav-link scrollto" href="/#team">
            <FormattedMessage id="nav.team"/>
          </a>
        </li>
        <li>
          <a className="nav-link scrollto" href="/#application">
            <FormattedMessage id="nav.app"/>
          </a>
        </li>
        <li>
          <a className="nav-link scrollto" href="/#partenaires">
            <FormattedMessage id="nav.partners"/>
          </a>
        </li>
        <li>
          <a className="nav-link scrollto" href="/#medias">
            <FormattedMessage id="nav.media"/>
          </a>
        </li>
        <li>
          <a className="nav-link scrollto" href="/#origines">
            <FormattedMessage id="nav.origin"/>
          </a>
        </li>
        <li>
          <a className="nav-link scrollto" href="/#contact">
            <FormattedMessage id="nav.contact"/>
          </a>
        </li>
        <li>
          <a target="_blank">
            <Select compact options={options} defaultValue='fr' onChange={props.onLanguageChange}/>
          </a>
        </li>
      </ul>
      <i className="bi bi-list mobile-nav-toggle"/>
    </nav>
  );
}

export default NavBar;

NavBar.propTypes = {
  onLanguageChange: PropTypes.func.isRequired,
}