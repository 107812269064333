import React from "react";
import {FormattedMessage} from "react-intl";

const GoalSection = () => (
  <section id="objectifs" className="objectifs">
    <div className="container" data-aos="fade-up">
      <h2><FormattedMessage id="goals.title" /></h2>
      <p>
        <FormattedMessage id="goals.first_paragraph" />
      </p>
      <p>
        <FormattedMessage id="goals.second_paragraph" />
      </p>
      <p>
        <FormattedMessage id="goals.third_paragraph" />{" "}
        <a
          href="assets/doc/Guide utilisateur  SylvCiT_2022_VF.docx"
          target="_blank"
        >
          <FormattedMessage id="goals.third_paragraph_link" />
        </a>
        .
      </p>
      <br />
      <div className="row feature-icons" data-aos="fade-up">
        <div className="row">
          <div
            className="col-xl-4 text-center"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="image-credit-wrapper">
              <a href="https://comtois-etienne.com/" target="_blank" className="credit-padding image-credit">Photo by Étienne Comtois
              </a>
              <img src="assets/img/objectifs.jpg" className="img-fluid p-2" alt=""/>
            </div>
          </div>
          <div className="col-xl-8 d-flex content">
            <div className="row align-self-center gy-4">
              <div className="col-md-6 icon-box" data-aos="fade-up">
                <i className="ri-bubble-chart-line" />
                <div>
                  <h4><FormattedMessage id="goals.first_goal" /></h4>
                  <p>
                    <FormattedMessage id="goals.first_goal_description" />
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 icon-box"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <i className="ri-line-chart-line" />
                <div>
                  <h4><FormattedMessage id="goals.second_goal" /></h4>
                  <p>
                    <FormattedMessage id="goals.second_goal_description" />
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 icon-box"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <i className="bi-tree" />
                <div>
                  <h4><FormattedMessage id="goals.third_goal" /></h4>
                  <p>
                    <FormattedMessage id="goals.third_goal_description" />
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 icon-box"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <i className="ri-survey-line" />
                <div>
                  <h4><FormattedMessage id="goals.fourth_goal" /></h4>
                  <p>
                    <FormattedMessage id="goals.fourth_goal_description" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container pt-4 " data-aos="fade-up" data-aos-delay={200}>
      <div className="content">
        <div className="funct">
          <p>
            <FormattedMessage id="goals.functional_diveristy_description" />
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default GoalSection;
