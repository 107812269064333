import React, { Component } from 'react'


export default class NumberTrees extends Component {
  
  render() {
    const { number } = this.props.value
    // const { disabled } = this.props.value
    return (
      <div>
        <input type='number' min={0} max={300} value={number} onChange={(e)=>this.props.onChange(e.target.value)} />
      </div>
    )
  }
}