import React, { Component } from 'react';
import { Header, List, Grid, Image, Message} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button'
import Table from "../components/App/Table";
import { connect } from "react-redux";
import axios from "axios";
import { storing_species } from "../actions/actions";
import Checkbox from '../components/App/Checkbox';
import Chart from "../components/App/chart";
import SummaryBoxRow from '../components/App/SummaryBoxRow';
import ReactExport from "react-export-excel";
import LoadingOverlay from 'react-loading-overlay';
import { FormattedMessage, injectIntl } from 'react-intl';
import {withRouter} from "../utils/Routing";
const injectGetMessage = (fn) => 
  React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)));

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const speciesList = [
	{ 'Species': 'Populus x canadensis', 'Species Diversity Improvement': '1.7%', 'Func Group Diversity Improvement': '1.7%', 'Species Richness Improvement': '1.7%', 'Carbon Storage Improvement': '1.7%', 'Value ($) Improvement': '1.7%' },
	{ 'Species': 'Populus x canadensis', 'Species Diversity Improvement': '1.7%', 'Func Group Diversity Improvement': '1.7%', 'Species Richness Improvement': '1.7%', 'Carbon Storage Improvement': '1.7%', 'Value ($) Improvement': '1.7%' },
	{ 'Species': 'Populus x canadensis', 'Species Diversity Improvement': '1.7%', 'Func Group Diversity Improvement': '1.7%', 'Species Richness Improvement': '1.7%', 'Carbon Storage Improvement': '1.7%', 'Value ($) Improvement': '1.7%' },
	{ 'Species': 'Populus x canadensis', 'Species Diversity Improvement': '1.7%', 'Func Group Diversity Improvement': '1.7%', 'Species Richness Improvement': '1.7%', 'Carbon Storage Improvement': '1.7%', 'Value ($) Improvement': '1.7%' },
	{ 'Species': 'Populus x canadensis', 'Species Diversity Improvement': '1.7%', 'Func Group Diversity Improvement': '1.7%', 'Species Richness Improvement': '1.7%', 'Carbon Storage Improvement': '1.7%', 'Value ($) Improvement': '1.7%' },
	{ 'Species': 'Populus x canadensis', 'Species Diversity Improvement': '1.7%', 'Func Group Diversity Improvement': '1.7%', 'Species Richness Improvement': '1.7%', 'Carbon Storage Improvement': '1.7%', 'Value ($) Improvement': '1.7%' }];


//array containing name of functional groups
const func_group = [
	{ id: 1, group: '1A' },
	{ id: 2, group: '1B' },
	{ id: 3, group: '2A' },
	{ id: 4, group: '2B' },
	{ id: 5, group: '2C' },
	{ id: 6, group: '3A' },
	{ id: 7, group: '3B' },
	{ id: 8, group: '4A' },
	{ id: 9, group: '4B' },
	{ id: 10, group: '5' },
];

//const url_backend = '/backend'
const url_backend = process.env.REACT_APP_ENDPOINT
const url_get_species = url_backend + '/api/get_species_selected_groups';
//const url_get_species = 'http://localhost:8000/api/get_species_selected_groups';


class SelectGroup extends Component {

	constructor(props) {
		super(props);
		console.log(this.props)
		this.state = {
			data: [],
			loading: false, // will be true when ajax request is running
			curr_status : this.props.curr_status,
			group_list: this.props.recommended_groups || {},
			checkedItems: new Map(Object.entries(this.props.recommended_groups || {})),
			percentage_by_group: this.props.percentage_by_group || {},
		}
		this.handleChange = this.handleChange.bind(this);
	}

	handleClick(e) {
		e.preventDefault();
		console.log(this.state.checkedItems)
		this.setState({ loading: true }, () => {
		axios.post(url_get_species, {
			group_list: Object.fromEntries(this.state.checkedItems)
		}).then(res => {
			console.log(res.data)
			this.setState({
				loading: false
			})
			this.props.dispatch(storing_species(res.data));
			this.props.navigate({
				pathname: '/app/selectspecies'
			});
		}).catch(err => {
			console.log('error ' + err)
		})
	});
	}

	/*checkboxChangeHandler(e) {
		const item = e.target.name;
		const isChecked = e.target.checked;
		console.log(item + ' ' + isChecked)
	  }*/
	handleChange(e) {
		const item = e.target.name;
		const isChecked = e.target.checked;
		this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
		console.log(item + "   " + isChecked)
	}

	getFileName() {
        let d = new Date();
        let dformat = `${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}-${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
        // console.log(dformat)
        // var newDate = new Date();
        // let dformat = parseInt(newDate.getMonth()+1)+`-`+newDate.getDate()+`-`+newDate.getFullYear()+`-`+newDate.getTime()
        console.log(dformat)
        return "SylvCiT_report_"+dformat
    }


	render() {
		var data;
		// var data_recommended_groups;
		const { ranking } = this.props;
		const { curr_status } = this.props;
		const { loading } = this.state;

		/*if(recommended_groups!==undefined) {
			data_recommended_groups = recommended_groups
			this.setState({group_list: data_recommended_groups})
		}*/

		if (ranking !== undefined && curr_status!== undefined) {
			data = ranking
			console.log(data)
			console.log(curr_status)
		}
		else {
			//this.setState({data: speciesList})
			data = speciesList
		}

		return (
			<React.Fragment >
				{/* <HeaderMenu /> */}
				<div>
					{/* Breadcrumb navigation to navigate between pages*/}
					<div className="ui breadcrumb">
						<Link to="/"><FormattedMessage id="status.sel_area" /></Link>
						<div className="divider"> /</div>
						<Link to="/status"><FormattedMessage id="status.curr_status" /></Link>
						<div className="divider"> /</div>
						<Link to="/settings"><FormattedMessage id="settings.sim_plantation" /></Link>
						<div className="divider"> /</div>
						<div className="active section"><FormattedMessage id="groups.sel_func_group" /></div>
					</div>

					<Header textAlign='center' as='h2'><FormattedMessage id="settings.sim_plantation" /></Header>
					<LoadingOverlay
						active={loading}
						spinner
						text={<FormattedMessage id="settings.load_species" />}
						// styles={{
						// 	overlay: (base) => ({
						// 	  ...base,
						// 	  background: '#A9A9A9'
						// 	})
						//   }}
						styles={{
							spinner: (base) => ({
								...base,
								width: '100px',
								'& svg circle': {
									stroke: '#2BAD60'
								}
							})
						}}
					>
					<Container fluid={true}>
					{/* <Row textAlign='right'>
                                <ExcelFile filename={this.getFileName()} element={ <Button className="green ui button" block="false" ><FormattedMessage id="download_report" /></Button>}>
								 <ExcelSheet data={data} name="recommended_species">
                                        {Object.keys(data[0]).map((item) => (
                                            // console.log(item)
                                            <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                        ))}
                                    </ExcelSheet> 
									<ExcelSheet data={curr_status.species_table} name="species">
                                        {Object.keys(curr_status.species_table[0]).map((item) => (
                                            // console.log(item)
                                            <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                        ))}
                                    </ExcelSheet>
                                    <ExcelSheet data={curr_status.genus_table} name="genus">
                                        {Object.keys(curr_status.genus_table[0]).map((item) => (
                                            // console.log(item)
                                            <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                        ))}
                                    </ExcelSheet>
                                    <ExcelSheet data={curr_status.family_table} name="families">
                                        {Object.keys(curr_status.family_table[0]).map((item) => (
                                            // console.log(item)
                                            <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                        ))}
                                    </ExcelSheet>
                                    <ExcelSheet data={curr_status.func_group_table} name="functional_groups">
                                        {Object.keys(curr_status.func_group_table[0]).map((item) => (
                                            // console.log(item)
                                            <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                        ))}
                                    </ExcelSheet>                             
                                    <ExcelSheet data={curr_status.replacement_value_table} name="replacement_value">
                                        {Object.keys(curr_status.replacement_value_table[0]).map((item) => (
                                            // console.log(item)
                                            <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                        ))}
                                    </ExcelSheet>
                                    <ExcelSheet data={curr_status.carbon_table} name="carbon_storage">
                                        {Object.keys(curr_status.carbon_table[0]).map((item) => (
                                            // console.log(item)
                                            <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                        ))}
                                    </ExcelSheet>
                                </ExcelFile>
								</Row> */}
						<Row>
							<Col md={12} lg={4} xl={4} style={{ padding : 20}} >
								{/* <Header dividing textAlign='left' as='h3'><FormattedMessage id="groups.func_group_suggestion" /></Header> */}
								<Header dividing textAlign='left' as='h3'><FormattedMessage id="status.curr_status" /></Header>
								<SummaryBoxRow
                                dataBox1={this.state.curr_status.number_trees}
                                titleBox1={<FormattedMessage id="status.nb_trees" />}
                                dataBox2={this.state.curr_status.group_richness}
                                titleBox2={<FormattedMessage id="status.func_group_richness" />}
                                dataBox3={this.state.curr_status.group_diversity.toFixed(2)}
                                titleBox3={<FormattedMessage id="status.func_group_diversity" />}
                            	/>
								<div className="center_graph_display">
								{injectGetMessage((getMessage) => <Chart data={this.state.curr_status.count_by_func_group} display_bar_label={true} title={getMessage({ id: 'status.nb_trees_func_group' })} xLabel={getMessage({ id: 'func_group_abr' })} yLabel={getMessage({ id: 'home.sel_nb_trees' })}/>)}
                                        {/* <FormattedMessage id="status.nb_trees_func_group">
                                            {(title) =>
                                                <Chart data={this.state.curr_status.count_by_func_group} display_bar_label={true} title={title} padding_left={50} padding_bottom={40} className="test-chart" />
                                            }
                                        </FormattedMessage> */}
                                </div>
							</Col>
						{/* </Row> */}
						{/* <Row> */}
							<Col md={12} lg={8} xl={8} style={{ padding : 20}}>
								<Row>
								<Header dividing textAlign='left' as='h3'><FormattedMessage id="groups.func_group_suggestion" /></Header>
								<List horizontal relaxed className="list_groups">
									{func_group.map((item) => (
										<List.Item key={item.id}>
											<Checkbox checked={this.state.checkedItems.get(item.group)}
												// onChange={this.handleChange} 
												onChange={e => this.handleChange(e)}
												name={item.group}
												label={<label> <FormattedMessage id="groups.func_group" /> {item.group}</label>} />
											{/* {<label style={{ paddingLeft : 10}}> <FormattedMessage id="groups.func_group" /> {item.group} ({this.state.percentage_by_group[item.group] || 0}%)</label>} */}
											{<label style={{ paddingLeft : 10}}> <FormattedMessage id="groups.func_group" /> {item.group}</label>}
										</List.Item>

									))}
								</List>
								</Row>
								{/* <Header dividing as='h3'><FormattedMessage id="groups.recom_sel_based" /></Header> */}
								<Row>
								<Message info>
                                <Message.Header><FormattedMessage id="groups.recommendations" /></Message.Header>
                                <p>
                                    <FormattedMessage id="groups.recommendations_desc" />
                                </p>
                            	</Message>
									<Table data={data}/>
								</Row>
								<Button onClick={e => this.handleClick(e)} className="green ui button" size="lg" block="false" ><FormattedMessage id="groups.follow_suggestion" /></Button>
								&nbsp;
								{/* <Row>
								<h4>Recommendations for you :</h4>
							</Row>
							<Row>
								<ul>
									<li>1. Replace "Acer" and "Fraxinus" trees</li>
									<li>2. Plant trees of functional group 4A</li>
									<li>3. Plant trees of functional group 4B</li>
									<li>4. Plant trees of functional group 5</li>
								</ul>
							</Row> */}
							</Col>

						</Row>
					</Container>
					</LoadingOverlay>
				</div>
				{/* <FooterMenu/>   */}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	curr_status: state.curr_status,
	ranking: state.ranking,
	recommended_groups: state.recommended_groups,
	percentage_by_group: state.percentage_by_group,
});

export default connect(mapStateToProps)
	(
		withRouter(SelectGroup)
	)
	;
