import React from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { StylesControl } from 'mapbox-gl-controls';
import * as turf from '@turf/turf'
// import DrawControl from 'react-mapbox-gl-draw';
// import MapboxDraw from "mapbox-gl-draw";
// import MapboxDraw from "react-mapbox-gl-draw";
import { FormattedMessage, FormattedNumber } from 'react-intl';
// import StylesControl from 'mapbox-gl-controls/lib/styles';
import "mapbox-gl/dist/mapbox-gl.css";
import { connect } from 'react-redux';
import { Button, Image, Grid, Segment, Select, Menu, Form, List, ListItem, Progress, Message, Header, Icon, Modal, Embed } from 'semantic-ui-react'
// import ZoomControl from 'mapbox-gl-controls/lib/zoom';


import axios from 'axios';
import "react-placeholder/lib/reactPlaceholder.css";
import { storing_curr_status2 } from "../../actions/actions";
import { storing_selection } from "../../actions/actions";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { ProgressBar } from 'react-bootstrap';
import SummaryBoxRow from './SummaryBoxRow';
import FuncGroupDesc from './FuncGroupDesc'
// import { ListItem } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {withRouter} from "../../utils/Routing";

mapboxgl.accessToken = "pk.eyJ1IjoicmFvdWJlbCIsImEiOiJja2lnYXIwNzYwaWcxMzNvMzk2anlzczAwIn0.pRyJedaJeAyEU4hTPKt28g";

const options = [
    { key: 'ha', text: 'ha', value: 'ha' },
    { key: 'km²', text: 'km²', value: 'km' },
    { key: 'm²', text: 'm²', value: 'm' },
]
const { stringify } = require('wkt');
const url_backend = process.env.REACT_APP_ENDPOINT
const url_all_selected_trees = url_backend + '/api/get_all_selected_trees';
const url_selected_trees_details = url_backend + '/api/get_selected_trees_details';
const url_tree_detail = url_backend + '/api/get_tree_detail';
class MapBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lng: -73.568190,
            lat: 45.509235,
            zoom: 16,
            activate_bounds: false,
            boundsToFit: null,
            selected_value: 0,
            selected_mode: "",
            selection_info: {},
            data: [],
            data_by_species: [],
            map_bounds: null,
            markers: null,
            numTrees: 0,
            numTreesSelected: 0,
            numSpecies: 0,
            num_old_trees: 0,
            area_size: 0,
            area_size_displayed: 0,
            selected_unit: 'm',
            polygon_state: true,
            rectangle_state: true,
            circle_state: true,
            shape_on_map: false,
            circle_radius: 0,
            shape_coordinates: "",
            shape_type: "",
            collapsed: false,
            selected: 'home',
            tree_detail: {},
            button_ha: true,
            button_km: false,
            button_m: false,
            show_details: false,
            open: false,
        };
    }
    componentDidMount() {
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/raoubel/ckju61qh910cc19qfmxcr9o42',
            //   style: "mapbox://styles/mapbox/streets-v11",
            center: [this.state.lng, this.state.lat],
            cluster: true,
            zoom: this.state.zoom
        });

        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        // map.addControl(new ZoomControl(), 'top-right');
        // with custom styles

        map.addControl(new StylesControl({
            styles: [
                {
                    className: "mapbox-gl-draw_point",
                    label: 'Streets',
                    styleName: 'Mapbox Streets',
                    styleUrl: 'mapbox://styles/raoubel/ckju61qh910cc19qfmxcr9o42',

                }, {
                    label: 'Satellite',
                    styleName: 'Satellite',
                    styleUrl: 'mapbox://styles/raoubel/ckk47uayp554w17lj9xx650k9',
                },
                {
                    label: 'Climate',
                    styleName: 'Climate',
                    // styleUrl: 'mapbox://styles/raoubel/ckjutl2nt008019pg2plc0434',
                    styleUrl: 'mapbox://styles/raoubel/ckk6bu2e00k4318lgjizee8zq',
                },
                // {
                //     label: 'NaN',
                //     styleName: 'NaN',
                //     // styleUrl: 'mapbox://styles/raoubel/ckjutl2nt008019pg2plc0434',
                //     styleUrl: 'mapbox://styles/raoubel/ckknbabrs5kev17pcejleiih5',
                // },

            ],
            onChange: (style) => console.log(style),
        }), 'top-left');
        map.setStyle('mapbox://styles/raoubel/ckju61qh910cc19qfmxcr9o42');


        var Draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
            },
            userProperties: true,

        });
        map.addControl(Draw, 'top-right');

        map.on('draw.create', (e) => {
            this.getDrawInfo(e, Draw)
        }
        );
        map.on('draw.delete', (e) => {
            console.log('delete')
            this.shapeManager(true)
            this.setState({ area_size: 0 })
            this.setState({ area_size_displayed: 0 })
            this.setState({ num_old_trees: 0 })
            this.setState({ numTreesSelected: 0 })
            this.setState({ shape_on_map: false })
            this.setState({ shape_coordinates: "" })
            this.setState({ circle_radius: 0 })
            this.setState({ shape_type: "" })
        }
        );

        map.on('draw.update', (e) => {
            console.log('update')
            this.getDrawInfo(e, Draw)
        }
        );
        // map.on('draw.delete', updateArea);
        // map.on('draw.update', updateArea);


        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        });

        // When a click event occurs on a feature in the places layer, open a popup at the
        // location of the feature, with description HTML from its properties.
        map.on('click', 'mtl-trees', (e) => {
            var coordinates = e.features[0].geometry.coordinates.slice();
            var species_latin = e.features[0].properties.species_latin;
            var tree_id = e.features[0].properties.tree_id;
            console.log(e.features[0])
            console.log(species_latin)
            console.log(tree_id)
            // let tree_id = elem.tree_id
            axios.post(url_tree_detail, {
                tree_id: tree_id
            }).then(res => {
                // this.props.dispatch(storing_curr_status2(res.data));
                console.log(res.data.docs[0])
                this.setState({ tree_detail: res.data.docs[0] })
                this.setState({ show_details: true })

            }).catch(err => {
                console.log('error ' + err)
            })
        });
        // When a click event occurs on a feature in the places layer, open a popup at the
        // location of the feature, with description HTML from its properties.
        map.on('click', 'mtl-trees-dates', (e) => {
            var coordinates = e.features[0].geometry.coordinates.slice();
            var species_latin = e.features[0].properties.species_latin;
            var tree_id = e.features[0].properties.tree_id;
            console.log(e.features[0])
            console.log(species_latin)
            console.log(tree_id)
            // let tree_id = elem.tree_id
            axios.post(url_tree_detail, {
                tree_id: tree_id
            }).then(res => {
                // this.props.dispatch(storing_curr_status2(res.data));
                console.log(res.data.docs[0])
                this.setState({ tree_detail: res.data.docs[0] })
                this.setState({ show_details: true })

            }).catch(err => {
                console.log('error ' + err)
            })
        });
        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', "mtl-trees", function () {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', "mtl-trees", function () {
            map.getCanvas().style.cursor = '';
        });
        map.on('load', () => {
            this.getLayerDetails(map)
        });
        map.on('moveend', () => {
            console.log('moveend')
            this.getLayerDetails(map)
        });

        // map.on("render", (map, ev) => {
        //     console.log('render')
        //     console.log(ev)
        //     // var filteredcount = map.queryRenderedFeatures({ layers: ['mtl-trees'] }).length;
        //     // console.log(filteredcount)
        // });

    }
    async getSpeciesProportions(trees) {
        //trees_species = trees.map(({specie_french})=>specie_french);
        // console.log('SpeciesProportions')
        // console.log(trees)
        var lookup = {};
        var items = trees;
        var result = [];
        var colors = {};
        for (var item, i = 0; item = items[i++];) {
            var name = item.properties.species_latin;
            var color_r = item.layer.paint["circle-color"].r;
            var color_g = item.layer.paint["circle-color"].g;
            var color_b = item.layer.paint["circle-color"].b;

            if (!(name in lookup)) {
                lookup[name] = 1;
                result.push(name);
            } else {
                lookup[name] += 1;
            }

            if (!(name in colors)) {
                colors[name] = this.rgbToHex(Math.round(color_r * 255), Math.round(color_g * 255), Math.round(color_b * 255));
            }
        }
        var trees_proportions = []
        for (var key in lookup) {
            var specie_prop = {
                "name": key,
                "nb_trees": lookup[key],
                "color": colors[key],
                "percentage": this.getPercentage(lookup[key], trees.length)
            }
            trees_proportions.push(specie_prop)
        }
        trees_proportions.sort((a, b) => (a.nb_trees > b.nb_trees) ? -1 : 1)

        this.setState({ numSpecies: result.length })
        this.setState({ data_by_species: trees_proportions })
    }

    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    rgbToHex(r, g, b) {
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }
    getPercentage(percent, total) {
        return ((percent / total) * 100).toFixed(2)
    }

    getLayerDetails(map) {

        const currentZoom = map.getZoom()
        console.log(currentZoom)
        var features = map.queryRenderedFeatures({ layers: ['mtl-trees'] });
        var uniqueFeatures = this.getUniqueFeatures(features, 'species_latin');
        // console.log(features)
        // console.log(uniqueFeatures.length)
        if (currentZoom > 10) {
            this.setState({ numTrees: features.length })
            this.setState({ numSpecies: uniqueFeatures.length })
        }
        this.getSpeciesProportions(features)
    }

    shapeManager(state) {
        this.setState({ rectangle_state: state })
        this.setState({ circle_state: state })
        this.setState({ polygon_state: state })
    }
    getDrawInfo(e, Draw) {
        console.log(e)
        var data = Draw.getAll();
        if (data.features.length > 0) {
            var area = turf.area(data);
            // restrict to area to 2 decimal points
            var rounded_area = Math.round(area * 100) / 100;
            this.setState({ area_size: area })
            this.computeUnit(this.state.selected_unit)
            // console.log(rounded_area)
            // console.log(data)
            let wktCoords = stringify(data.features[0])
            this.getSelectedTreesDetails(url_selected_trees_details, wktCoords)
            this.setState({ shape_coordinates: wktCoords })
            this.setState({ shape_type: 'polygon' })
        } else {
            console.log('draw.delete')
        }
    }

    getUniqueFeatures(array, comparatorProperty) {
        var existingFeatureKeys = {};
        // Because features come from tiled vector data, feature geometries may be split
        // or duplicated across tile boundaries and, as a result, features may appear
        // multiple times in query results.
        var uniqueFeatures = array.filter(function (el) {
            if (existingFeatureKeys[el.properties[comparatorProperty]]) {
                return false;
            } else {
                existingFeatureKeys[el.properties[comparatorProperty]] = true;
                return true;
            }
        });

        return uniqueFeatures;
    }
    getSelectedTreesDetails(api_url, polygon_coordinates) {
        axios.post(api_url, {
            polygon_coordinates: polygon_coordinates
        }).then(res => {
            this.setState({ numTreesSelected: res.data.number_trees })
            this.setState({ num_old_trees: res.data.nb_old_trees })
            let info = { trees: res.data.number_trees, old_trees: res.data.nb_old_trees, trees_ha: Math.round((res.data.number_trees) / (this.convertMToHectares(this.state.area_size))) || 0 }
            this.setState({
                selection_info: info
            }, () => {
                this.props.dispatch(storing_selection(info));
            });

        }).catch(err => {
            console.log('error' + err)
        })
    }
    computeUnit(selected_unit) {
        switch (selected_unit) {
            case 'ha':
                this.setState({ area_size_displayed: this.convertMToHectares(this.state.area_size) })
                break;
            case 'km':
                console.log(this.convertSqMToSqKms(this.state.area_size))
                this.setState({ area_size_displayed: this.convertSqMToSqKms(this.state.area_size) }, () => {
                });
                break;
            case 'm':
                this.setState({ area_size_displayed: (this.state.area_size) })
                break;
            default:
                this.setState({ area_size_displayed: (this.state.area_size) })
        }
    }

    handleChange = (event, data) => {
        console.log(event)
        console.log(data.value);
        this.setState({ selected_unit: data.value }, () => {
            this.computeUnit(this.state.selected_unit)
        });

    };

    getCircleArea(radius) {
        return Math.PI * radius * radius;
    }

    convertSqMToSqKms(sq_meters) {
        return sq_meters / 1000000
    }

    convertMToHectares(sq_meters) {
        return sq_meters / 10000
    }
    setOpen(value) {
        this.setState({ open: value })
    }
    handleSubmit = event => {

        event.preventDefault();

        let shape_coordinates = this.state.shape_coordinates
        if (this.state.shape_type === 'circle') {
            let lat = shape_coordinates.lat
            let lng = shape_coordinates.lng
            shape_coordinates = lat + ',' + lng
        }

        /* axios.post(api_url, {
            radius: '' + radius / 1000,
            latlngs: latlngs_formatted*/
        let radius = this.state.circle_radius
        // axios.post(url_all_selected_trees, {
        //     shape_coordinates: shape_coordinates,
        //     shape_type: this.state.shape_type,
        //     radius: '' + radius / 1000
        // }).then(res => {
        //     this.props.dispatch(storing_curr_status2(res.data, this.state.selection_info));
        // }).catch(err => {
        //     console.log('error ' + err)
        // })
        axios.post(url_all_selected_trees, {
            shape_coordinates: shape_coordinates
        }).then(res => {
            this.props.dispatch(storing_curr_status2(res.data, this.state.selection_info));
        }).catch(err => {
            console.log('error ' + err)
        })
        console.log(this.props)
        this.props.navigate({
            pathname: '/app/status', state: this.state.selection_info
        });

    }
    handleOnCloseDetails = (e) => {
        e.preventDefault();
        this.setState({ show_details: false })
    }

    render() {
        return (
            <React.Fragment >
                {/* <HeaderMenu /> */}
                <Grid className="noPadding" >

                    <Row style={{ padding: "0" }}>

                        <Col md={2} className="SpecieInfoPadding" >

                            {this.state.show_details ?
                                (
                                    <Card
                                        style={{ "maxHeight": "94%" }}
                                        color="primary"
                                    >
                                    <Button floated='right' icon onClick={((e) => this.handleOnCloseDetails(e))}>
                                        <Icon name='close' />
                                    </Button>
                                        <Card.Header><h2><FormattedMessage id="home.tree_details" /></h2></Card.Header>
                                        <Card.Body>
                                            <List relaxed size={'large'} divided style={{ "padding": ".7em" }}>

                                                <List.Item>
                                                    <List.Content><img src={(this.state.tree_detail.image_url != "no_data" ? this.state.tree_detail.image_url : "https://react.semantic-ui.com/images/wireframe/image.png")} className="ui image" /> 
                                                    </List.Content>
                                                    <List.Content>Source : <a href = "https://arbres.hydroquebec.com/recherche-arbres-arbustes">Hydro-Québec</a></List.Content>

                                                </List.Item>

                                                <List.Item>
                                                    <List.Content><FormattedMessage id="home.sp_name" /> (EN) : {this.state.tree_detail.specie_english}</List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Content><FormattedMessage id="home.sp_name" /> (FR) : {this.state.tree_detail.specie_french}</List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Content><FormattedMessage id="home.sp_name" /> (LA) : {this.state.tree_detail.specie_latin}</List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    {this.state.tree_detail.max_DBH !== undefined ?
                                                        <List.Content floated='right' style={{ "fontSize": ".7em" }}>
                                                            <FormattedMessage id="home.dbh_max" />
                                                        </List.Content> : null}
                                                    {this.state.tree_detail.max_DBH !== undefined ? <List.Content style={{ "fontSize": ".7em" }}><FormattedMessage id="home.dbh" /> <ProgressBar variant="success" now={this.state.tree_detail.DBH} label={`${this.state.tree_detail.DBH} / ${this.state.tree_detail.max_DBH}`} max={this.state.tree_detail.max_DBH} /></List.Content> :
                                                        <List.Content><FormattedMessage id="home.dbh" /> : <FormattedNumber value={this.state.tree_detail.DBH} /></List.Content>}
                                                </List.Item>
                                                <List.Item>
                                                    <List.Content><FormattedMessage id="home.family" />: {this.state.tree_detail.family}</List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Content><FormattedMessage id="home.genus" /> : {this.state.tree_detail.genus}</List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Content><FormattedMessage id="home.date_plant" /> : {this.state.tree_detail.date_plant != "00" ? this.state.tree_detail.date_plant : "N/A"}</List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Content><FormattedMessage id="home.date_measures" /> : {this.state.tree_detail.date_measures!= "00" ? this.state.tree_detail.date_measures: "N/A"}</List.Content>
                                                </List.Item>

                                                {(this.state.tree_detail.hq_details_url != "no_data" ?
                                                    <ListItem>
                                                        <List.Content><Link to={{ pathname: this.state.tree_detail.hq_details_url }} target="_blank"><FormattedMessage id="home.more_details" /></Link></List.Content>
                                                    </ListItem> :
                                                    null)}
                                                <ListItem>

                                                </ListItem>
                                                <List.Item>
                                                    <List.Content><FuncGroupDesc func_group={this.state.tree_detail.func_group} /></List.Content>
                                                </List.Item>

                                            </List>
                                        </Card.Body>
                                    </Card>)
                                :
                                <Card>

                                    <Card.Header><h2><FormattedMessage id="home.about" /> <FormattedMessage id="home.app_name" /></h2></Card.Header>
                                    <Card.Body>
                                        <Tabs id="controlled-tab-example"
                                        >
                                            <Tab eventKey="tutoriel" title={"Tutoriel"}>
                                                <List relaxed size={'large'} divided style={{ "padding": ".7em" }}>
                                                    <List.Item>
                                                        <Icon name='language' size='large' style={{ color: "blue" }} />
                                                        <List.Content>
                                                        <FormattedMessage id="sylvcit.language" />
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item>
                                                        <Icon name='map outline' size='large' style={{ color: "green" }} />
                                                        <List.Content>
                                                        <FormattedMessage id="sylvcit.map" />
                                                        </List.Content>
                                                    </List.Item>

                                                    <List.Item>
                                                        <Icon name='circle' size='large' style={{ color: "green" }} />
                                                        <List.Content>
                                                        <FormattedMessage id="sylvcit.map_symbols" />
                                                        </List.Content>
                                                    </List.Item>

                                                    <List.Item>
                                                        <Icon name='object ungroup outline' size='large' style={{ color: "black" }} />

                                                        <List.Content>
                                                        <FormattedMessage id="sylvcit.map_selection" />
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item>
                                                        <Icon name='trash alternate' size='large' style={{ color: "black" }} />

                                                        <List.Content>
                                                        <FormattedMessage id="sylvcit.delete_selection" />
                                                    </List.Content>
                                                    </List.Item>

                                                </List>
                                            </Tab>
                                            <Tab eventKey="home" title={<FormattedMessage id="home.why_sylvcit" />}>
                                                {/* <h3><FormattedMessage id="home.why_sylvcit" /></h3> */}
                                                <br />
                                                <p><FormattedMessage id="home.desc_p1" /></p>
                                                <p><FormattedMessage id="home.desc_p2" /></p>
                                                <p><FormattedMessage id="home.desc_p3" /></p>
                                            </Tab>
                                            <Tab eventKey="profile" title={<FormattedMessage id="home.desc_p4" />}>
                                                <br />
                                                {/* <h3><FormattedMessage id="home.desc_p4" /></h3> */}
                                                <p><strong><FormattedMessage id="home.app_name" /></strong> <FormattedMessage id="home.desc_p5" /><br /></p>
                                                <p><FormattedMessage id="home.desc_p6" /></p>
                                                <p><FormattedMessage id="home.desc_p7" /></p>
                                            </Tab>

                                        </Tabs>


                                    </Card.Body>
                                </Card>
                            }

                        </Col>
                        <Col md={7} className="noPadding">

                            <SummaryBoxRow
                                dataBox1={this.state.numTrees}
                                titleBox1={<FormattedMessage id="home.trees_in_view" />}
                                dataBox2={this.state.numSpecies}
                                titleBox2={<FormattedMessage id="home.unique_trees_in_view" />}

                            />
                            <div ref={el => this.mapContainer = el} className='map-container' />
                            <Message className='noMargin' color='red'>{<FormattedMessage id="home.beta_message" />}</Message>
                        </Col>
                        <Col md={3} className="noPadding">
                            <Card
                                className="SummaryMapCol"
                                // style={{ "maxHeight": "100vh" }}
                                color="primary"
                            >
                                <Card.Header><h2><FormattedMessage id="home.trees_info" /></h2></Card.Header>
                                <Card.Body className="noPadding">

                                    <List relaxed size={'large'} divided style={{ "padding": ".7em" }}>
                                        <List.Item>
                                            <List.Content floated='right'>
                                                <Select compact options={options} defaultValue='m' onChange={this.handleChange} />
                                            </List.Content>
                                            <List.Content><FormattedMessage id="home.sel_area_size" />: <FormattedNumber value={(this.state.area_size_displayed).toFixed(3)} /></List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content><FormattedMessage id="home.sel_nb_trees" />:<FormattedNumber value={this.state.numTreesSelected} /> </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content><FormattedMessage id="home.sel_nb_trees_ha" />: {Math.round((this.state.numTreesSelected) / (this.convertMToHectares(this.state.area_size))) || 0}</List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Content><FormattedMessage id="home.sel_nb_old_trees" />: <FormattedNumber value={this.state.num_old_trees} /></List.Content>
                                        </List.Item>
                                    </List>

                                    <Form onSubmit={this.handleSubmit.bind(this)} >
                                        <Button disabled={this.state.shape_coordinates === ""}
                                            className="btn btn-primary green ui fluid button"
                                            block="true"><FormattedMessage id="home.select_area" />
                                        </Button>
                                    </Form>


                                    <List divided relaxed className="list-group">
                                        {this.state.data_by_species.map((species, i) =>

                                            <List.Item key={i} style={{ "paddingLeft": "1rem", "paddingRight": "1rem" }}>

                                                <List.Content floated='right'>
                                                    <FormattedNumber value={species.percentage} /> %
                                                </List.Content>
                                                <Icon name='circle' size='large' style={{ color: species.color }} />
                                                <List.Content>{species.name}</List.Content>

                                            </List.Item>


                                        )}
                                    </List>

                                </Card.Body>
                            </Card>




                        </Col>
                    </Row>
                </Grid>

                {/* <FooterMenu/>   */}
            </React.Fragment >

        )
    }
}

const mapStateToProps = state => ({
});
export default connect(mapStateToProps)

    (
        withRouter(MapBox)
    )
    ;