import React from "react";
import {FormattedMessage} from "react-intl";

const Footer = () => (
    <footer id="footer" className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="/" className="logo d-flex align-items-center">
                <img src="assets/img/logo.png"/>
                <span>SylvCiT</span>
              </a>
              <p>
                <FormattedMessage id="home.subtitle"/>
              </p>
              <div className="social-links mt-3">
                <a href="#" className="linkedin">
                  <i className="bi bi-linkedin"/>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-6 footer-links">
              <h4><FormattedMessage id="quick_links"/></h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"/>{" "}
                  <a href="/#about"><FormattedMessage id="nav.about"/></a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"/>{" "}
                  <a href="/#application"><FormattedMessage id="nav.app"/></a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"/>
                  <a href="/medias"><FormattedMessage id="nav.media"/></a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"/>{" "}
                  <a href="/terms">
                    <FormattedMessage id="footer.terms_conditions"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          © Copyright 2023{" "}
          <strong>
            <span>SylvCiT</span>
          </strong>
          . <FormattedMessage id="footer.rights"/>
        </div>
      </div>
    </footer>

);

export default Footer;
