import React from "react";
import {FormattedMessage} from "react-intl";

const IntroSection = () => (
  <section id="hero" className="hero d-flex align-items-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">
            <FormattedMessage id="home.title" />
          </h1>
          <h2 data-aos="fade-up" data-aos-delay={400}>
            <FormattedMessage id="home.subtitle" />
          </h2>
          <div data-aos="fade-up" data-aos-delay={600}>
            <div className="text-center text-lg-end">
              <a
                href="/app"
                className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                target="_blank"
              >
                <span><FormattedMessage id="home.access" /></span>
                <i className="bi bi-arrow-right" />
              </a>
            </div>
          </div>
        </div>
        <div
          className="col-lg-6 hero-img"
          data-aos="zoom-out"
          data-aos-delay={200}
        >
          <img src="assets/img/hero-img.png" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
);

export default IntroSection;
