import React from "react";
import Footer from "./Footer";
import {FormattedMessage} from "react-intl";
const MediaDetail = () => (

    <main id="main">

        <section className="breadcrumbs">
            <div className="container">

                <h2><FormattedMessage id="nav.media"/></h2>

            </div>
        </section>

        <section id="blog" className="blog">
            <div className="container" data-aos="fade-up">


                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-10.jpg" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="https://ici.radio-canada.ca/nouvelle/2090242/montreal-planter-arbres-secteur-prive"
                           target="_blank">
                            Montréal veut inciter le secteur privé à planter plus d’arbres
                        </a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2024-07-24">24 juillet 2024</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            La Ville de Montréal sollicite l'aide des secteurs privé et institutionnel pour atteindre son objectif de planter un demi-million d'arbres sur son territoire d'ici 2030.
                            Depuis 2020, elle en a planté 180 000, mais la tâche commence à se compliquer. La grande majorité des espaces disponibles sur les terrains municipaux ont déjà été utilisés...
                        </p>
                        <div className="read-more">
                            <a href="https://ici.radio-canada.ca/nouvelle/2090242/montreal-planter-arbres-secteur-prive"
                               target="_blank"><FormattedMessage id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-9.jpg" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="https://www.lapresse.ca/dialogue/opinions/2024-02-20/replique-hydro-quebec-et-l-abattage/a-la-recherche-des-meilleures-solutions-pour-la-sante-des-arbres.php"
                           target="_blank">
                            À la recherche des meilleures solutions pour la santé des arbres
                        </a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2024-02-20">20 février 2024</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            La lettre de Mme Millet et cosignataires publiée dans l’édition du 13 février1 de La Presse
                            sur la désertification des rues interpelle Hydro-Québec et la Chaire Hydro-Québec sur le
                            contrôle
                            de la croissance des arbres de l’UQAM (Chaire ArbrenVil). Comme titulaire de cette Chaire,
                            je me dois
                            de rectifier certaines affirmations faites par Mme Millet et cosignataires et expliquer le
                            rôle et
                            fonctionnement des chaires de recherche dans les universités...
                        </p>
                        <div className="read-more">
                            <a href="https://www.lapresse.ca/dialogue/opinions/2024-02-20/replique-hydro-quebec-et-l-abattage/a-la-recherche-des-meilleures-solutions-pour-la-sante-des-arbres.php"
                               target="_blank"><FormattedMessage id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-1.jpg" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="https://www.lareleve.qc.ca/2023/03/08/boucherville-et-varennes-participent-a-une-etude-sur-la-foresterie-urbaine/"
                           target="_blank">Boucherville et Varennes participent à une étude sur la foresterie urbaine</a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2023-03-08">8 mars 2023</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            Les villes de Boucherville et de Varennes prennent part à un projet de recherche de la
                            Chaire Hydro-Québec sur le contrôle de la croissance des arbres en ville (Arbrenvil) de
                            l’UQAM | Université du Québec à Montréal.
                            Les chercheurs étudieront la composition actuelle de la forêt urbaine, en tenant compte des
                            changements climatiques, des îlots de chaleur et des insectes ravageurs, car les espèces qui
                            doivent être plantées aujourd’hui doivent être bien adaptées à l’environnement de demain si
                            l’on souhaite qu’elles survivent et jouent un rôle bénéfique dans les villes ...
                        </p>
                        <div className="read-more">
                            <a href="https://www.lareleve.qc.ca/2023/03/08/boucherville-et-varennes-participent-a-une-etude-sur-la-foresterie-urbaine/"
                               target="_blank"><FormattedMessage id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-2.jpg" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="https://actualites.uqam.ca/2023/developper-la-foresterie-urbaine-de-precision/"
                           target="_blank">2,7 M$ pour optimiser la forêt urbaine</a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2023-02-20">20 février 2023</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            La Chaire Hydro-Québec sur le contrôle de la croissance des arbres vient d’obtenir un peu
                            plus de 2,7 millions de dollars dans le cadre d’une subvention CRSNG Alliance pour un
                            nouveau projet de recherche intitulé «Arbrenvil: développement d’une foresterie urbaine de
                            précision pour optimiser le couvert forestier en ville». Il s’agit d’un troisième
                            financement pour la chaire, dont le mandat a ainsi été renouvelé jusqu’en 2027 ...
                        </p>
                        <div className="read-more">
                            <a href="https://actualites.uqam.ca/2023/developper-la-foresterie-urbaine-de-precision/"
                               target="_blank"><FormattedMessage id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-3.jpg" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="https://enclasse.telequebec.tv/contenu/SylCIT-mieux-planter-des-arbres-en-ville/21769"
                           target="_blank">SylvCiT : mieux planter des arbres en ville !</a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2023-01-01">janvier 2023</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            L’animateur Philippe Desrosiers se demande si l’intelligence artificielle ou IA pourrait
                            améliorer sa santé mentale. Il découvre qu’elle peut veiller sur nos émotions et dépister
                            des pensées suicidaires. Mais pour lui, le bonheur passe par la botanique... un domaine qui
                            n’échappe pas à l’IA ! ...
                        </p>
                        <div className="read-more">
                            <a href="https://enclasse.telequebec.tv/contenu/SylCIT-mieux-planter-des-arbres-en-ville/21769"
                               target="_blank"><FormattedMessage id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-4.jpg" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="https://enligne.telequebec.tv/emissions/334557/a-quel-point-nos-villes-sont-elles-intelligentes/68924/grace-a-la-technologie-sylcit"
                           target="_blank">
                            À quel point nos villes sont-elles intelligentes?<br/>
                            Grâce à la technologie : SylvCiT
                        </a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2021-10-11">11 octobre 2021</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            Montréal se classe 6e parmi les villes les plus intelligentes au monde. Mais qu'est-ce ça
                            veut dire? Nellie aide Isabelle Racicot à mieux vendre et acheter en ligne. On découvre un
                            logiciel qui aide à la gestion de la foresterie urbaine.<br/>
                            Planter des arbres en ville, ce n'est pas simple, mais les arbres y sont pourtant aussi
                            essentiels qu'en forêt. Le logiciel SylCIT permet de mieux planifier la forestation urbaine
                            et sensibilise les villes à l'importance de la diversité sur leur territoire ...
                        </p>
                        <div className="read-more">
                            <a href="https://enligne.telequebec.tv/emissions/334557/a-quel-point-nos-villes-sont-elles-intelligentes/68924/grace-a-la-technologie-sylcit"
                               target="_blank"><FormattedMessage id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-5.jpg" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="https://www.lareleve.qc.ca/2021/03/22/un-projet-de-recherche-sur-la-resilience-des-arbres-urbains-de-boucherville-et-de-varennes-soutenu-par-quebec/"
                           target="_blank">Un projet de recherche sur la résilience des arbres urbains de Boucherville
                            et de Varennes, soutenu par Québec</a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2021-03-22">22 mars 2021</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            Le Fonds de recherche du Québec Nature et technologies (FRQNT) a récemment dévoilé les six
                            récipiendaires de la première édition du programme pilote Visage municipal. Au nombre des
                            récipiendaires se retrouve un projet de recherche mené par des employés des villes de
                            Boucherville, Varennes et Saint-Lambert ...
                        </p>
                        <div className="read-more">
                            <a href="https://www.lareleve.qc.ca/2021/03/22/un-projet-de-recherche-sur-la-resilience-des-arbres-urbains-de-boucherville-et-de-varennes-soutenu-par-quebec/"
                               target="_blank"><FormattedMessage id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-6.jpg" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="https://ise.uqam.ca/non-classe/page/3/" target="_blank">Des professeurs de l’ISE
                            lauréats du concours du Rendez-vous Pile 2017!</a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2017-11-13">13 novembre 2017</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            Félicitations aux professeurs de l’ISE Christian Messier, Alain Paquette, Tanya Handa,
                            Daniel Kneeshaw et Thi Thanh Hien Pham, lauréats du concours organisé dans le cadre du
                            Rendez-vous Pile 2017 sur le thème: « Des solutions de recherche pour un mieux-être dans la
                            ville ». <br/>
                            Leur projet « Immuniser la forêt urbaine face aux changements globaux » leur vaut une bourse
                            de 10000 $ pour financer ce projet de recherche. BRAVO! ...
                        </p>
                        <div className="read-more">
                            <a href="https://ise.uqam.ca/non-classe/page/3/" target="_blank"><FormattedMessage
                                id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-7.jpg" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="http://actualites.uqam.ca/2017/rendez-vous-pile-les-gagnants/?fbclid=IwAR0jV87Dgu-mPPmrsCek1u2YaUnUgDR5mextFQ8NjEOn1-jvfR6Ky7CSDTU"
                           target="_blank">
                            Rendez-vous Pile: les gagnants <br/>
                            Les meilleurs projets d’innovation présentés par des étudiants et des professeurs ont séduit
                            leur jury respectif.
                        </a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2017-11-13">13 novembre 2017</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            Les lauréats des deux concours organisés dans le cadre du Rendez-vous Pile 2017 ont été
                            dévoilés le 9 novembre dernier, à l’occasion d’un gala qui avait lieu au MT Lab. Du côté des
                            étudiants, les gagnants sont Espace Blitz et Arpent, tandis que chez les professeurs, ce
                            sont les projets Flash sur mon quartier! et Immuniser la forêt urbaine face aux changements
                            globaux qui ont emporté la faveur du jury ...
                        </p>
                        <div className="read-more">
                            <a href="http://actualites.uqam.ca/2017/rendez-vous-pile-les-gagnants/?fbclid=IwAR0jV87Dgu-mPPmrsCek1u2YaUnUgDR5mextFQ8NjEOn1-jvfR6Ky7CSDTU"
                               target="_blank"><FormattedMessage id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

                <article className="entry">

                    <div className="entry-img">
                        <img src="assets/img/medias/media-8.png" alt="" className="img-fluid"/>
                    </div>

                    <h2 className="entry-title">
                        <a href="https://rvpile.uqam.ca/" target="_blank">
                            Félicitations aux gagnants de l’édition 2017
                        </a>
                    </h2>

                    <div className="entry-meta">
                        <ul>
                            <li className="d-flex align-items-center"><i className="bi bi-clock"></i>
                                <time dateTime="2017-11-09">9 novembre 2017</time>
                            </li>
                        </ul>
                    </div>

                    <div className="entry-content">
                        <p>
                            Dans le cadre des célébrations de l’UQAM entourant le 375e de Montréal, le Service des
                            partenariats et du soutien à l’innovation (SePSI) est heureux de tenir le Rendez-vous Pile,
                            le 9 novembre 2017, sous le thème « Des solutions de recherche pour un mieux-être dans la
                            ville » ...
                        </p>
                        <div className="read-more">
                            <a href="https://rvpile.uqam.ca/" target="_blank"><FormattedMessage
                                id="media.read_more"/></a>
                        </div>
                    </div>

                </article>

            </div>
        </section>
        <Footer></Footer>
    </main>
);

export default MediaDetail;