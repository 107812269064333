import React, { Component } from 'react';
import { Header, List, Checkbox, Message, Input, Card, Image, Icon, Divider } from 'semantic-ui-react';
import { Link} from 'react-router-dom';
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import Col from "react-bootstrap/Col";
import Table from "../components/App/Table";
import axios from 'axios';
import { storing_new_status } from "../actions/actions";
import LoadingSpinner from '../components/App/loadingSpinner';
import SummaryBoxRow from '../components/App/SummaryBoxRow';
import Chart from "../components/App/chart";
// import checked from "/images/checked.png"
// import { func_groups } from '/images/func_groups.png'
import '../components/Box/box.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import {withRouter} from "../utils/Routing";
const injectGetMessage = (fn) =>
	React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)));

//const url_backend = 'http://localhost:8000'
//const url_backend = '/backend'
const url_backend = process.env.REACT_APP_ENDPOINT
const url_get_new_status = url_backend + '/api/get_new_status';
const DefaultImprovementList = [
	{ 'Feature': 'Species richness', 'Current Value': '49', 'Future Value': '53', 'Improvement': '8.1%' },
	{ 'Feature': 'Species diversity', 'Current Value': '49', 'Future Value': '53', 'Improvement': '8.1%' },
	{ 'Feature': 'Func group richness', 'Current Value': '49', 'Future Value': '53', 'Improvement': '8.1%' },
	{ 'Feature': 'Func group diversity', 'Current Value': '49', 'Future Value': '53', 'Improvement': '8.1%' },
	{ 'Feature': 'Carbon Storage', 'Current Value': '49', 'Future Value': '53', 'Improvement': '8.1%' }];

const defaultSpeciesList = [
	{ id: 1, name: 'Ulmus rubra', func_group: '5', isChecked: false, nb_suggested: 0 },
	{ id: 2, name: 'Rhamnus frangula', func_group: '4A', isChecked: false, nb_suggested: 0 },
	{ id: 3, name: 'Prunus padus', func_group: '3A', isChecked: false, nb_suggested: 0 },
	{ id: 4, name: 'Ulmus propinqua', func_group: '1A', isChecked: true, nb_suggested: 2 },
]
class SelectSpecies extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false, // will be true when ajax request is running
			nb_selected: 0,
			nb_trees: (typeof this.props.nb_trees_plant === 'undefined') ? 10 : this.props.nb_trees_plant,
			nb_choices_left: (typeof this.props.nb_trees_plant === 'undefined') ? 10 : this.props.nb_trees_plant,
			// checkedItems: new Map(Object.entries(species)),
			speciesList: [],
			new_status: [],
			info_status: [],
			curr_status: this.props.curr_status,
			statusList: DefaultImprovementList,
			searchTerm: '',
			simulate: true,
		}
		//this.handleChange = this.handleChange.bind(this);
		// Cette liaison est nécéssaire afin de permettre    
		// l'utilisation de `this` dans la fonction de rappel.    
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick() {
		let curr_status_received = this.props.curr_status;
		let list_species = this.state.speciesList
		var selected_species = list_species.filter(obj => {
			return obj.isChecked
		})
		this.setState({ loading: true }, () => {
			axios.post(url_get_new_status, {
				shape_coordinates: curr_status_received.shape_coordinates,
				shape_type: curr_status_received.shape_type,
				radius: '' + curr_status_received.radius / 1000,
				list_new_species: selected_species
			}).then(res => {

				this.setState({
					loading: false
				})
				let new_status = res.data.new_status
				let info_new_status = res.data.info_status
				this.setState({
					info_status: [...this.state.info_status, info_new_status]
				})
				this.setState({
					new_status: [...this.state.new_status, new_status]
				})
				this.setState({
					simulate: false
				})

				this.props.dispatch(storing_new_status(res.data));
				let impList = this.state.statusList


				impList[0]['Future Value'] = new_status.prediction.species_richness
				impList[1]['Future Value'] = new_status.prediction.species_diversity.toFixed(2)
				impList[2]['Future Value'] = new_status.prediction.group_richness
				impList[3]['Future Value'] = new_status.prediction.group_diversity.toFixed(2)
				impList[4]['Future Value'] = new_status.prediction.total_carbon_storage.toFixed(2)

				impList[0]['Improvement'] = new_status.improvement_percentage.species_richness.toFixed(2) + '%'
				impList[1]['Improvement'] = new_status.improvement_percentage.species_diversity.toFixed(2) + '%'
				impList[2]['Improvement'] = new_status.improvement_percentage.group_richness.toFixed(2) + '%'
				impList[3]['Improvement'] = new_status.improvement_percentage.group_diversity.toFixed(2) + '%'
				impList[4]['Improvement'] = new_status.improvement_percentage.total_carbon_storage.toFixed(2) + '%'
				this.setState({
					statusList: [...this.state.statusList, impList]
				})
			}).catch(err => {
				console.log('error ' + err)
			})
		});
	}

	onAddingItem = (i) => (event) => {
		this.setState((state, props) => {
			state.speciesList[i].isChecked = !state.speciesList[i].isChecked;
			return {
				speciesList: state.speciesList
			}
		})
		if (this.state.speciesList[i].isChecked) {
			this.setState(prevState => {
				return { nb_selected: prevState.nb_selected - parseInt(this.state.speciesList[i].nb_suggested) }
			})


			this.setState((state, props) => {
				state.speciesList[i].nb_suggested = 0
				return {
					speciesList: state.speciesList
				}
			})
		}
		else {
			this.setState(prevState => {
				return { nb_selected: prevState.nb_selected + parseInt(this.state.speciesList[i].nb_suggested) }
			})
		}

		/*this.setState(prevState => {
			return {nb_selected: prevState.nb_selected + 1}
		})*/
	}

	handleChange = (i) => (event) => {
		let valueSelected = event.target.value
		//this.storeNbSelected(event.target.value)
		//TODO : Gestion de l'incrementation et de la décrementation
		this.setState(prevState => {
			if (valueSelected > this.state.speciesList[i].nb_suggested) {
				return { nb_selected: prevState.nb_selected + 1 }
			}
			else {
				return { nb_selected: prevState.nb_selected - 1 }
			}


		})

		this.storeNbSelected(event.target.value, i)
	}
	handleSubmit = event => {

		event.preventDefault();
		this.props.navigate({
			pathname: '/app/newstatus',
			state: this.state.new_status // your data array of objects
		});
	}

	findTreeById(trees, id) {
		return trees.find((element) => {
			return element.id === id;
		})
	}

	getIndex(id) {
		return this.state.speciesList.findIndex(obj => obj.id === id);
	}

	handleOnCardClick = (e, data, i) => {
		e.preventDefault();
		console.log(data);
		this.searchInput.focus();

		i = this.getIndex(data.id)
		// console.log(this.getIndex(data.id))
		console.log(i)
		this.setState((state, props) => {
			state.speciesList[i].isChecked = !state.speciesList[i].isChecked;
			return {
				speciesList: state.speciesList
			}
		})
		if (this.state.speciesList[i].isChecked) {
			this.setState(prevState => {
				return { nb_selected: prevState.nb_selected - parseInt(this.state.speciesList[i].nb_suggested) }
			})


			this.setState((state, props) => {
				state.speciesList[i].nb_suggested = 0
				return {
					speciesList: state.speciesList
				}
			})
		}
		else {
			this.setState(prevState => {
				return { nb_selected: prevState.nb_selected + parseInt(this.state.speciesList[i].nb_suggested) }
			})
		}
		// access to e.target here

	}


	storeNbSelected(value, i) {
		this.setState((state, props) => {
			state.speciesList[i].nb_suggested = value;
			return {
				speciesList: state.speciesList
			}
		})
	}
	componentDidMount() {
		//Get this.props
		console.log('componentDidMount')
		var data_received = []
		let species_received = this.props.species_list;
		if (species_received !== undefined) {
			for (let i = 0; i < species_received.length; i++) {
				var species_item = {}
				species_item.id = i
				species_item.name = species_received[i].species_latin
				species_item.func_group = species_received[i].func_group
				species_item.image_url = species_received[i].image_url
				species_item.hq_details_url = species_received[i].hq_details_url
				species_item.isChecked = false
				species_item.nb_suggested = 0
				data_received.push(species_item);
			}
		}
		else {
			data_received = defaultSpeciesList
		}
		this.setState({ speciesList: data_received })

		let curr_status_received = this.props.curr_status;
		let impList = DefaultImprovementList
		if (curr_status_received !== undefined) {
			console.log(curr_status_received)
			impList[0]['Current Value'] = curr_status_received.species_richness
			impList[1]['Current Value'] = curr_status_received.species_diversity.toFixed(2)
			impList[2]['Current Value'] = curr_status_received.group_richness
			impList[3]['Current Value'] = curr_status_received.group_diversity.toFixed(2)
			impList[4]['Current Value'] = curr_status_received.total_carbon_storage.toFixed(2)

			impList[0]['Future Value'] = curr_status_received.species_richness
			impList[1]['Future Value'] = curr_status_received.species_diversity.toFixed(2)
			impList[2]['Future Value'] = curr_status_received.group_richness
			impList[3]['Future Value'] = curr_status_received.group_diversity.toFixed(2)
			impList[4]['Future Value'] = curr_status_received.total_carbon_storage.toFixed(2)

			impList[0]['Improvement'] = "0%"
			impList[1]['Improvement'] = "0%"
			impList[2]['Improvement'] = "0%"
			impList[3]['Improvement'] = "0%"
			impList[4]['Improvement'] = "0%"
		}
		this.setState({ statusList: impList })


	};

	getColor(item) {
		switch (item.func_group) {
			case '1A':
				return 'green';
			case '1B':
				return 'olive';
			case '2A':
				return 'blue';
			case '2B':
				return 'teal';
			case '2C':
				return 'blue';
			case '3A':
				return 'orange';
			case '3B':
				return 'orange';
			case '4A':
				return 'orange';
			case '4B':
				return 'orange';
			case '5':
				return 'yellow';
			default:
				return 'white';
		}
	}

	editSearchTerm = (e) => {
		this.setState({ searchTerm: e.target.value })
	}
	dynamicSearch = () => {
		return this.state.speciesList.filter(tree => tree.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()))
	}
	render() {
		// console.log('render', this.props, this.state);
		const { loading } = this.state;
		return (
			<React.Fragment >
				{/* <HeaderMenu /> */}
				<div>
					{/* Breadcrumb navigation to navigate between pages*/}
					<div className="ui breadcrumb">
						<Link to="/"><FormattedMessage id="status.sel_area" /></Link>
						<div className="divider"> /</div>
						<Link to="/status"><FormattedMessage id="status.curr_status" /></Link>
						<div className="divider"> /</div>
						<Link to="/settings"><FormattedMessage id="settings.sim_plantation" /></Link>
						<div className="divider"> /</div>
						<Link to="/selectGroup"><FormattedMessage id="groups.sel_func_group" /></Link>
						<div className="divider"> /</div>
						<div className="active section"><FormattedMessage id="species.select_sp" /></div>
					</div>

					<Header textAlign='center' as='h2'><FormattedMessage id="settings.sim_plantation" /></Header>

					<Container fluid={true}>
						<Row>
							<Col xs={8}>
								<Header dividing textAlign='left' as='h3'><FormattedMessage id="species.sp_sel_groups" /></Header>
								<Input size='large' icon='search' placeholder='Search...' value={this.state.searchTerm} onChange={this.editSearchTerm} />
								<Divider />
								<Card.Group>
									{(this.dynamicSearch()).map((item, i) => (
										<Card color={this.getColor(item)} key={i}>
											{/* {console.log(item)} */}
											<Card.Content onClick={((e) => this.handleOnCardClick(e, item))}>

												{/* <Image
													alt='tree_img'
													size='small'
													src={(item.image_url != "no_data" ? item.image_url : "https://react.semantic-ui.com/images/wireframe/image.png")}
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												/> */}
												<img
													src={(item.image_url != "no_data" ? item.image_url : "https://react.semantic-ui.com/images/wireframe/image.png")}
													className="ui image"
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
													}} />

												{item.isChecked ?
													<Image
														visible
														floated='right'
														size='mini'
														src="/images/checked.png"
													/> :
													<Image
														hidden
														floated='right'
														size='mini'
														src="/images/unchecked.png"
													/>}

												{/* <Icon color='green' name='users' /> */}
												<Divider />
												<Card.Header>{item.name} {item.condition == "Oui" ? <Icon color='yellow' name='attention' floated='right' /> : null}</Card.Header>
												<Card.Meta><FormattedMessage id="groups.func_group" /> : {item.func_group} <Icon color={this.getColor(item)} name='circle' /></Card.Meta>

											</Card.Content>
											<Card.Content extra>
												<span><FormattedMessage id="status.nb_trees" /></span>
												<input onChange={this.handleChange(i)}
													disabled={!item.isChecked}
													size='2'
													type='number'
													pattern="^-?[0-9]\d*\.?\d*$"
													min={0}
													ref={inputEl => (this.searchInput = inputEl)}
													// ref={(ip) => this.myInp = ip}
													max={this.state.nb_choices_left}
													value={this.state.speciesList[i].nb_suggested} />
												{(item.hq_details_url != "no_data" ?
													<Link to={{ pathname: item.hq_details_url }} target="_blank"><FormattedMessage id="home.more_details" /></Link>
													:
													null)}
												{/* <span>{item.hq_details_url}</span> */}
											</Card.Content>
										</Card>

									))}

								</Card.Group>

							</Col>
							<Col xs={4}>

								<Message info>
									<Message.Header><FormattedMessage id="species.important" /></Message.Header>
									<p>
										<FormattedMessage id="species.improve_diversity" />
									</p>
									<p>
										<FormattedMessage id="species.random_list" />
									</p>
									<p>
										<Icon color='yellow' name='attention' floated='right' /> <FormattedMessage id="species.plant_condition" />
									</p>
								</Message>
								<div>
									{/* <h4 style={{ textAlign: "center" }}>{this.state.nb_selected}/{this.state.nb_trees} <FormattedMessage id="species.selected" /></h4> */}
									{/* <Button onClick={this.handleClick} variant="primary" size="lg" block> Simulate</Button> */}
									{/* <Button  onClick={this.handleClick} className="green ui button" block="false" >Simulate</Button>

					<Button onClick={e => this.handleSubmit(e)}  variant="primary" size="lg" block>
					Plant new trees
					</Button> */}
<Row></Row>
					<Button onClick={e => this.handleClick(e)} className="blue ui button" size="lg" block="false" ><FormattedMessage id="species.simulate" /></Button>
					&nbsp;
					<Button disabled={this.state.simulate} onClick={e => this.handleSubmit(e)} className="btn-primary green ui button" block="false"><FormattedMessage id="status.plant_new_trees" /></Button>
								</div>
								<Row>
									{loading ?
										<LoadingSpinner />
										:
										<Table data={this.state.statusList} />
									}
								</Row>
								<Row>
									<Image
										// floated='right'
										fluid
										src="/images/func_groups.png"
									/>
								</Row>


								<Divider />
								<Row>
									<Header dividing textAlign='left' as='h3'><FormattedMessage id="status.sel_status" /></Header>
									<SummaryBoxRow
										dataBox1={this.state.curr_status.number_trees}
										titleBox1={<FormattedMessage id="status.nb_trees" />}
										dataBox2={this.state.curr_status.group_richness}
										titleBox2={<FormattedMessage id="status.func_group_richness" />}
										dataBox3={this.state.curr_status.group_diversity.toFixed(2)}
										titleBox3={<FormattedMessage id="status.func_group_diversity" />}
									/>
									<div className="center_graph_display">
										{injectGetMessage((getMessage) => <Chart data={this.state.curr_status.count_by_func_group} display_bar_label={true} title={getMessage({ id: 'status.nb_trees_func_group' })} xLabel={getMessage({ id: 'func_group_abr' })} yLabel={getMessage({ id: 'home.sel_nb_trees' })} />)}
										{/* <FormattedMessage id="status.nb_trees_func_group">
                                            {(title) =>
                                                <Chart data={this.state.curr_status.count_by_func_group} display_bar_label={true} title={title} padding_left={50} padding_bottom={40} className="test-chart" />
                                            }
                                        </FormattedMessage> */}
									</div>
								</Row>
							</Col>

						</Row>

					</Container>

				</div>
				{/* <FooterMenu/>   */}
			</React.Fragment>
		);
	}

}

const mapStateToProps = state => ({
	curr_status: state.curr_status,
	new_status: state.new_status,
	ranking: state.ranking,
	groups: state.groups,
	species_list: state.species_list,
	nb_trees_plant: state.nb_trees_plant
});

export default connect(mapStateToProps)
	(
		withRouter(SelectSpecies)
	);

