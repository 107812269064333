import React, { Component } from 'react';
import Box from '../Box';
import formatMoney from '../../utils/format';
import { Button, Image, Grid } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

function getGroupInfo (type) {
    var groups = {
      '1A': {color:"Box--1A", description:<FormattedMessage id="group_desc_1a" />},
      '1B': {color:"Box--1B", description:<FormattedMessage id="group_desc_1b" />},
      '2A': {color:"Box--2A", description:<FormattedMessage id="group_desc_2a" />},
      '2B': {color:"Box--2B", description:<FormattedMessage id="group_desc_2b" />},
      '2C': {color:"Box--2C", description:<FormattedMessage id="group_desc_2c" />},
      '3A': {color:"Box--3A", description:<FormattedMessage id="group_desc_3a" />},
      '3B': {color:"Box--3B", description:<FormattedMessage id="group_desc_3b" />},
      '4A': {color:"Box--4A", description:<FormattedMessage id="group_desc_4a" />},
      '4B': {color:"Box--4B", description:<FormattedMessage id="group_desc_4b" />},
      '5': {color:"Box--5", description:<FormattedMessage id="group_desc_5" />},
      undefined : {color:"#ffffff", description:"N/A"},
    };
    return groups[type] || "N/A";
  }

const FuncGroupDesc = (props) => {

  const {
    func_group
  } = props;
  console.log(func_group)
  var info_group = getGroupInfo(func_group);
  console.log(info_group);
  return (
    <div className="row" >
      <div className="col-md-12" style={{ "paddingRight": "0", "paddingLeft":"0"}} >
        <Box className="SummaryBox Box Box--4B" className={info_group.color}>

          <strong className="SummaryBox-value" style={{"float": "left"}}>
            {func_group || "N/A"}
          </strong>
          <h3 className="SummaryBox-desc pt-4">
            <strong>{info_group.description}</strong>
          </h3>
        </Box>
      </div>
    </div>
  );
};

// SummaryBoxRow.propTypes = {
//   orders: PropTypes.number,
//   profit: PropTypes.number,
//   emails: PropTypes.number,
//   toBeShipped: PropTypes.number
// };

export default FuncGroupDesc;