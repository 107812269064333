
export default function reducer(state ={}, action){

	switch(action.type){
		case "SELECTION":
			return Object.assign({},state,{selected : action.payload
			})

		case "STORING_CURR_STATUS":
			return Object.assign({},state,{curr_status:action.data.curr_status,
				info:action.data.info})
		
		case "STORING_NEW_STATUS":
			return Object.assign({},state,{new_status:action.data})

		case "STORING_SELECTION":
			return Object.assign({},state,{selection_stats:action.data})

		case "STORING_RANKING":
			return Object.assign({},state,{ranking:action.data.ranking, recommended_groups: action.data.recommended_groups, percentage_by_group: action.data.percentage_by_group, nb_trees_plant: action.data.nb_trees_plant})
        case "STORING_SPECIES":
			return Object.assign({},state, {groups: action.data.groups, species_list: action.data.species_list})
		default:
			return state;

	}

}