


export const selection = (value) => ({
	type: "SELECTION",
	payload: value
});


export const storing_curr_status = (value) => ({
	type: "STORING_CURR_STATUS",
	data: {district:value.district,curr_status:value.curr_status}

});

export const storing_curr_status2 = (value,value2) => ({
	type: "STORING_CURR_STATUS",
	data: {info:value2,district:value.district,curr_status:value.curr_status}

});

export const storing_selection = (value ) => ({
	type: "STORING_SELECTION",
	data: {num_trees:value.trees,old_trees:value.old_trees, trees_ha:value.trees_ha}

});

export const storing_new_status = (value) => ({
	type: "STORING_NEW_STATUS",
	data: {new_status:value.new_status, info_status:value.info_status}

});


export const storing_ranking = (value) => (
	{
	type: "STORING_RANKING",
	data: {ranking:value.ranking, recommended_groups:value.recommended_groups, percentage_by_group:value.percentage_by_group, nb_trees_plant:value.nb_trees_plant}
});



export const storing_species = (value) => ({
	type: "STORING_SPECIES",
	data: {groups: value.groups, species_list: value.species_list}

});