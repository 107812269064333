import React, { Component, createElement } from 'react'
import { Segment, Header, Button, Grid, Form, Checkbox, Radio } from 'semantic-ui-react'
import RatingFeatures from '../components/App/RatingFeatures'
import LoadingOverlay from 'react-loading-overlay';
import { renderToString } from 'react-dom/server'
import NumberTrees from '../components/App/NumberTrees'
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { VictoryChart, VictoryTheme, VictoryLabel, VictoryPolarAxis, VictoryArea, VictoryGroup } from "victory";
import axios from "axios";
import { storing_ranking } from "../actions/actions";
import { connect } from "react-redux";
// import HeaderMenu from '../header_menu'
// import saveSvgAsPng from "save-svg-as-png"

// import { PDFDownloadLink } from '@react-pdf/renderer';
// import { PdfDocument } from "../SylvPDFTemplate";
import { renderToStaticMarkup } from 'react-dom/server';

import Canvg, { presets } from 'canvg';
import { FormattedMessage, injectIntl } from 'react-intl';
import {withRouter} from "../utils/Routing";

class SvgBackground extends React.Component {
	render() {
		return (
			<svg xmlns='http://www.w3.org/2000/svg' width={100} height={100}>
				<rect width={100} height={100} fill='#269' />
				<g fill='#6494b7'>
					<rect width={100} height={1} y={20} />
					<rect width={100} height={1} y={40} />
					<rect width={100} height={1} y={60} />
					<rect width={1} height={100} x={20} />
					<rect width={1} height={100} x={40} />
					<rect width={1} height={100} x={60} />
				</g>
				<rect
					width={100}
					height={100}
					fill='none'
					strokeWidth={2}
					stroke='#fff'
				/>
			</svg>
		);
	}
}

const characterData = [
	{ 'Species_Richness': 1, 'runoff': 1, 'Carbon_Storage': 1, 'Species_Diversity': 1, 'heat_island': 1, 'Value': 1/*, 'air_pollution': 1, 'energy_cost': 1*/ },
	{ 'Species_Richness': 10, 'runoff': 10, 'Carbon_Storage': 10, 'Species_Diversity': 10, 'heat_island': 10, 'Value': 10/*, 'air_pollution': 10, 'energy_cost': 10 */}];
//const url_backend = '/backend'
const url_backend = process.env.REACT_APP_ENDPOINT
const url_get_groups = url_backend + '/api/get_groups';
const svgString = encodeURIComponent(renderToStaticMarkup(<SvgBackground />));


const dataUri = ("data:image/svg+xml," + svgString);

//const url_get_groups = 'http://localhost:8000/api/get_groups';
class Settings extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {
				Species_Richness: '1',
				Species_Diversity: '1',
				Func_group_Richness: '1',
				Carbon_Storage: '1',
				Value: '1',
				num_trees: '1',
				heat_island: '1',
				runoff: '1',
				//air_pollution: '1',
				//energy_cost: '1',
			},
			radioValue : 'all',
			Func_group_diversity: true,
			loading: false, // will be true when ajax request is running
			dataChart: this.processData(characterData),
			maxima: this.getMaxima(characterData),
			characterData: [
				{ 'Species_Richness': 1, 'runoff': 1, 'Carbon_Storage': 1, 'Species_Diversity': 1, 'heat_island': 1, 'Value': 1/*, 'air_pollution': 1, 'energy_cost': 1*/ },
				{ 'Species_Richness': 10, 'runoff': 10, 'Carbon_Storage': 10, 'Species_Diversity': 10, 'heat_island': 10, 'Value': 10/*, 'air_pollution': 10, 'energy_cost': 10*/ }]
		};
	}

	// handleChangeRadio = (e, { value }) => this.setState({ radioValue : value })
	handleChangeRadio = (e, { value }) => {
		console.log(value)
		this.setState({ radioValue : value })
	}
	handleChange(feat, val) {
		this.setState({
			data: { ...this.state.data, [feat]: val }
		});
		let data = this.state.characterData

		if (feat !== 'num_trees') {
			data[0][feat] = val;
			this.setState({ dataChart: this.processData(data) })
			this.setState({ maxima: this.getMaxima(data) })
			this.setState({ characterData: data })
		}

		//console.log(this.state.characterData)
	}

	handleCheck = (e) => {
		console.log(this.state.Func_group_diversity)
		this.setState((prevState) => ({ Func_group_diversity: !prevState.Func_group_diversity }))
	}

	getMaxima(data) {
		const groupedData = Object.keys(data[0]).reduce((memo, key) => {
			memo[key] = data.map((d) => d[key]);
			return memo;
		}, {});
		return Object.keys(groupedData).reduce((memo, key) => {
			memo[key] = Math.max(...groupedData[key]);
			return memo;
		}, {});
	}

	processData(data) {
		const maxByGroup = this.getMaxima(data);
		const makeDataArray = (d) => {
			return Object.keys(d).map((key) => {
				return { x: key, y: d[key] / maxByGroup[key] };
			});
		};

		return data.map((datum) => makeDataArray(datum));
	}


	handleSubmit = event => {

		event.preventDefault();
		console.log(this.state.Func_group_diversity)
		this.setState({ loading: true }, () => {
			axios.post(url_get_groups, {
				species_diversity_range: this.state.data.Species_Diversity,
				species_richness_range: this.state.data.Species_Richness,
				group_diversity_range: this.state.Func_group_diversity ? 10 : 0,
				group_richness_range: this.state.data.Func_group_Richness,
				carbon_storage_range: this.state.data.Carbon_Storage,
				value_range: this.state.data.Value,
				num_plants: this.state.data.num_trees,
				trees_location: this.state.radioValue
			}).then(res => {
				this.setState({
					loading: false
				})
				console.log(res.data)
				this.props.dispatch(storing_ranking(res.data));
				this.props.navigate({
					pathname: '/app/selectgroup'
				});
			}).catch(err => {
				console.log('error ' + err)
			})
		});
	}


	componentDidMount() {
	}
	render() {
		// const history = useHistory()
		const { data, loading } = this.state;
		return (
			<React.Fragment >
				{/* <HeaderMenu /> */}
				<div>
					{/* Breadcrumb navigation to navigate between pages*/}
					<div className="ui breadcrumb">
						<Link to="/"><FormattedMessage id="status.sel_area" /></Link>
						<div className="divider"> / </div>
						<Link to="/status"><FormattedMessage id="status.curr_status" /></Link>
						<div className="divider"> / </div>
						<div className="active section"><FormattedMessage id="settings.sim_plantation" /></div>
					</div>
					<Header textAlign='center' as='h1'>
						<FormattedMessage id="settings.planting_new_trees" />
					</Header>


					<LoadingOverlay
						active={loading}
						spinner
						text={<FormattedMessage id="settings.load_grp_sp" />}
						// styles={{
						// 	overlay: (base) => ({
						// 	  ...base,
						// 	  background: '#A9A9A9'
						// 	})
						//   }}
						styles={{
							spinner: (base) => ({
								...base,
								width: '100px',
								'& svg circle': {
									stroke: '#2BAD60'
								}
							})
						}}
					>

						<Segment>
							<h4><FormattedMessage id="settings.nb_trees_plant" /></h4>
								<NumberTrees value={data.num_trees} onChange={(val) => this.handleChange('num_trees', val)} />
							<h4>
								<FormattedMessage id="settings.imp_importance" />
							</h4>

							<Container fluid={true}>
								{/*
						Check the status of the 'loading' variable. If true, then display
						the loading spinner. Otherwise, display the results.
						*/}
								<Row>


								</Row>
								<Row>
									<Col md={"auto"} lg={8}>
										{/* Grid for positioning of inputs and buttons*/}
										<Row>
											<Col xs={6}>
												<label><strong><FormattedMessage id="status.sp_richness" /></strong></label>
												<RatingFeatures value={data.Species_Richness} onChange={(val) => this.handleChange('Species_Richness', val)} />

											</Col>

											<Col xs={6}>
												<label><strong><FormattedMessage id="status.sp_diversity" /></strong></label>
												<RatingFeatures value={data.Species_Diversity} onChange={(val) => this.handleChange('Species_Diversity', val)} />

											</Col>
										</Row>
										{/* <Row>
											<Col xs={6}>
												<RatingFeatures value={data.Func_group_Richness} onChange={(val) => this.handleChange('Func_group_Richness', val)} />
												<label><FormattedMessage id="status.function_group_richness" /></label>
											</Col>
											<Col xs={6}>
												<RatingFeatures value={data.Func_group_diversity} onChange={(val) => this.handleChange('Func_group_diversity', val)} />
												<label><FormattedMessage id="status.function_group_diversity" /></label>
											</Col>
										</Row> */}
										<Row>
											<Col xs={6}>
												<label><strong><FormattedMessage id="settings.carbon_storage" /></strong></label>
												<RatingFeatures value={data.Carbon_Storage} onChange={(val) => this.handleChange('Carbon_Storage', val)} />

											</Col>
											{/* <Col xs={6}>
												<label><strong><FormattedMessage id="settings.value" /></strong></label>
												<RatingFeatures value={data.Value} onChange={(val) => this.handleChange('Value', val)} />
											</Col> */}
										</Row>
										
										<Row>
											<Col>
											<h4><FormattedMessage id="settings.parks_street" /></h4>
												{/* <label><strong><FormattedMessage id="settings.parks_street" /></strong></label> */}
												<Form>
												<Form.Group inline>
													<Form.Field>
														<Radio
															label={this.props.intl.formatMessage({id: 'settings.street'})}
															name='radioGroup'
															value='street'
															checked={this.state.radioValue === 'street'}
															onChange={this.handleChangeRadio}
														/>
													</Form.Field>
													<Form.Field>
														<Radio
															label={this.props.intl.formatMessage({id: 'settings.parks'})}
															name='radioGroup'
															value='parks'
															checked={this.state.radioValue === 'parks'}
															onChange={this.handleChangeRadio}
														/>
													</Form.Field>
													<Form.Field>
														<Radio
															label={this.props.intl.formatMessage({id: 'settings.all_millieux'})}
															name='radioGroup'
															value='all'
															checked={this.state.radioValue === 'all'}
															onChange={this.handleChangeRadio}
														/>
													</Form.Field>
												</Form.Group>
												</Form>

											</Col>
											<Col>
												<Checkbox label='Améliorer la diversité fonctionnelle' checked={this.state.Func_group_diversity} onChange={e => this.handleCheck(e)} readOnly style={{ visibility:"hidden" }} />
											</Col>
										</Row>
										<h4><FormattedMessage id="settings.in_dev" /></h4>
										<Row>
											<Col xs={6}>
												<label><strong><FormattedMessage id="settings.heat_island" /></strong></label>
												<RatingFeatures value={data.heat_island} onChange={(val) => this.handleChange('heat_island', val)} disabled={true}/>

											</Col>
											<Col xs={6}>
												<label><strong><FormattedMessage id="settings.runoff" /></strong></label>
												<RatingFeatures value={data.runoff} onChange={(val) => this.handleChange('runoff', val)} disabled={true}/>

											</Col>
										</Row>
										{/*	<Row>
											<Col xs={6}>
												<label><strong><FormattedMessage id="settings.air_pollution" /></strong></label>
												<RatingFeatures value={data.air_pollution} onChange={(val) => this.handleChange('air_pollution', val)} disabled={true}/>

											</Col>
											<Col xs={6}>
												<label><strong><FormattedMessage id="settings.energy_cost" /></strong></label>
												<RatingFeatures value={data.energy_cost} onChange={(val) => this.handleChange('energy_cost', val)} disabled={true}/>

											</Col>
										</Row>*/}
									</Col>
									<Col md={"auto"} lg={4}>
										<VictoryChart polar
											theme={VictoryTheme.material}
											domain={{ y: [0, 1] }}
											style={{ parent: { width: 400, height: 400 } }}
										>
											<VictoryGroup colorScale={["green", "tomato"]}
												style={{ data: { fillOpacity: 0.2, strokeWidth: 2 } }}
											>
												{this.state.dataChart.map((data, i) => {
													return <VictoryArea key={i} data={data} />;
												})}
											</VictoryGroup>
											{
												Object.keys(this.state.maxima).map((key, i) => {
													return (
														<VictoryPolarAxis key={i} dependentAxis
															style={{
																axisLabel: { padding: 10 },
																axis: { stroke: "none" },
																grid: { stroke: "grey", strokeWidth: 0.25, opacity: 0.5 }
															}}
															tickLabelComponent={
																<VictoryLabel labelPlacement="vertical" />
															}
															labelPlacement="perpendicular"
															axisValue={i + 1} label={key}
															tickFormat={(t) => Math.ceil(t * this.state.maxima[key])}
															tickValues={[0.25, 0.5, 0.75]}
														/>
													);
												})
											}
											<VictoryPolarAxis
												labelPlacement="parallel"
												tickFormat={() => ""}
												style={{
													axis: { stroke: "none" },
													grid: { stroke: "grey", opacity: 0.5 }
												}}
											/>

										</VictoryChart>
									</Col>
								</Row>
								<Row>

									<Grid>
										<Grid.Row columns={2}>
											<Grid.Column textAlign='right' width='8'>


												<Button className="green ui button" block="false" disabled> <FormattedMessage id="settings.back" /></Button>


											</Grid.Column>
											<Grid.Column textAlign='left' width='8'>
												<Form onSubmit={this.handleSubmit.bind(this)} >
													<Button className="btn-primary green ui button" block="false"><FormattedMessage id="settings.next" /></Button>
												</Form>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Row>
							</Container>
						</Segment>
						{/* </LoadingOverlay> */}
					</LoadingOverlay>



				</div>
				{/* <FooterMenu/>   */}
			</React.Fragment>
		);
	}
}


const mapStateToProps = state => ({
	ranking: state.ranking,
	recommended_groups: state.recommended_groups,
	percentage_by_group: state.percentage_by_group,
	nb_trees_plant: state.nb_trees_plant,
});

export default connect(mapStateToProps)
	(
		withRouter(injectIntl(Settings))
	)
	;
