import React, { Component } from 'react';
import { Tab, Header, Button, Grid, Form, Message, Segment,Statistic, Popup, Icon} from 'semantic-ui-react';
// import func_groups from '/images/func_groups.png'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { VictoryPie, VictoryChart, VictoryLegend, VictoryTheme, VictoryTooltip } from "victory";


import Chart from "../components/App/chart";
import Table from "../components/App/Table";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LoadingSpinner from '../components/App/loadingSpinner';
import SummaryBoxRow from '../components/App/SummaryBoxRow';

import ReactExport from "react-export-excel";
import { FormattedMessage, injectIntl } from 'react-intl';
import {withRouter} from "../utils/Routing";

const injectGetMessage = (fn) => 
  React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)));

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Status extends Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        console.log('componentDidMount')
    }
    componentDidUpdate(prevProps, prevState) {
        console.log('componentDidUpdate')
    }
    handleSubmit = event => {

        event.preventDefault();

        this.props.navigate({
            pathname: '/app/settings'
        });

    }
    getFileName() {
        let d = new Date();
        let dformat = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}-${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
        // console.log(dformat)
        // var newDate = new Date();
        // let dformat = parseInt(newDate.getMonth()+1)+`-`+newDate.getDate()+`-`+newDate.getFullYear()+`-`+newDate.getTime()
        console.log(dformat)
        return "SylvCiT_report_" + dformat
    }

    render() {
        console.log('render')
        const { curr_status } = this.props;
        const { intl } = this.props;
        var stats = { old_trees: 0, trees: 0, trees_ha: 0 }
        var data;
        var dataPie;
        console.log(this.props)
        console.log(curr_status)
        console.log('render')

        const legendData = [
            { name: 'total trees' }, { name: 'old trees' }
        ];

        if (curr_status !== undefined) {
          console.log(this.props.location.state);
            if (this.props.location.state !== undefined && this.props.location.state !== null) {
                stats = this.props.location.state;
                stats.trees = curr_status.number_trees
                dataPie = [
                    { x: 1, y: stats.trees }, { x: 2, y: stats.old_trees }
                ];

            }

            data = [
                {
                    name: intl.formatMessage({ id: 'status.func_groups' }), content: (
                        <Container fluid={true}>
                            <SummaryBoxRow
                                dataBox1={curr_status.number_trees}
                                titleBox1={<FormattedMessage id="status.nb_trees" />}
                                dataBox2={curr_status.group_richness}
                                titleBox2={<FormattedMessage id="status.func_group_richness" />}
                                dataBox3={curr_status.group_diversity.toFixed(2)}
                                titleBox3={<FormattedMessage id="status.func_group_diversity" />}
                            />
                            <span>&nbsp;</span>
                            <Row>
                                <img src="/images/func_groups.png" className="func-img center" />
                            </Row>
                          <br />
                            <Row>
                                <Col>
                                
                                    <div>
                                    {injectGetMessage((getMessage) => <Chart data={curr_status.count_by_func_group} display_bar_label={true} 
                                    title={getMessage({ id: 'status.nb_trees_func_group' })} xLabel={getMessage({ id: 'func_group_abr' })} yLabel={getMessage({ id: 'home.sel_nb_trees' })}/>)}
                                        {/* <FormattedMessage id="status.nb_trees_func_group">
                                            
                                            {(title) =>
                                                <Chart data={curr_status.count_by_func_group} display_bar_label={true} title={title} xLabel={"Groupe fonctionnel"} yLabel="Nombre"/>
                                            }
                                        </FormattedMessage> */}
                                    </div>
                                </Col>
                                <Col>
                                    <Table data={curr_status.func_group_table} />
                                </Col>
                            </Row>

                        </Container>
                    )

                },
                {
                    name: intl.formatMessage({ id: 'status.santamour' }), content: (
                        <Container fluid={true}>
                            <Message info>
                                <Message.Header><FormattedMessage id="status.rule_102030" /></Message.Header>
                                <p>
                                    <FormattedMessage id="status.santamour_desc" />
                                </p>
                                <Message.Header><FormattedMessage id="status.rule_51030" /></Message.Header>
                                <p>
                                    <FormattedMessage id="status.rule_51030_desc" />
                                </p>
                            </Message>
                            <Row>
                                <Col>
                                {injectGetMessage((getMessage) => <Chart data={curr_status.percentage_by_species} show_bar={true} y_bar={10} y2_bar={5} display_bar_label={true} fixOverlap={false} title={getMessage({ id: 'status.prop_spec' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'Percentage' })}/>)}
                                </Col>
                                <Col>
                                    {injectGetMessage((getMessage) => <Chart data={curr_status.percentage_by_genus} show_bar={true} y_bar={20} y2_bar={10} display_bar_label={true} fixOverlap={false} title={getMessage({ id: 'status.prop_genus' })} xLabel={getMessage({ id: 'genus' })} yLabel={getMessage({ id: 'Percentage' })}/>)}
                                </Col>
                            </Row>

                            <Row className="graph_display">
                            {injectGetMessage((getMessage) => <Chart data={curr_status.percentage_by_family} show_bar={true} y_bar={30} y2_bar={15} display_bar_label={true} fixOverlap={false} title={getMessage({ id: 'status.prop_family' })} xLabel={getMessage({ id: 'family' })} yLabel={getMessage({ id: 'Percentage' })}/>)}

                            </Row>
                        </Container>

                    )
                },
                {
                    name: intl.formatMessage({ id: 'status.species' }), content: (
                        <Container fluid={true}>

                            <SummaryBoxRow
                                dataBox1={curr_status.number_trees}
                                titleBox1={<FormattedMessage id="status.nb_trees" />}
                                dataBox2={curr_status.species_richness}
                                titleBox2={<FormattedMessage id="status.sp_richness" />}
                                dataBox3={curr_status.species_diversity.toFixed(2)}
                                titleBox3={<FormattedMessage id="status.sp_diversity" />}
                            />
                            <span>&nbsp;</span>

                            <Row>
                                <Col>
                                    {injectGetMessage((getMessage) => <Chart id="top_species_chart" data={curr_status.top_species} display_bar_label={true} 
                                     title={getMessage({ id: 'status.sp_top' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'Nb d\'arbres' })}/>)}

                                </Col>
                                <Col>
                                {injectGetMessage((getMessage) => <Chart id="top_species_chart" data={curr_status.top_species_all} display_bar_label={true} 
                                     title={getMessage({ id: 'status.sp_top_hyb' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'Nb d\'arbres' })}/>)}
                                    {/* <div>
                                        <FormattedMessage id="status.sp_top_hyb">
                                            {(title) =>
                                                <Chart id="top_species_chart" data={curr_status.top_species_all} display_bar_label={true} title={title} />
                                            }
                                        </FormattedMessage>
                                    </div> */}

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table data={curr_status.species_table} />
                                </Col>
                            </Row>

                        </Container>
                    )
                },


                {
                    name: intl.formatMessage({ id: 'status.dhp' }), content: (
                        <Container fluid={true}>
                            <Message info>
                                <Message.Header><FormattedMessage id="status.dbh_max_title" /></Message.Header>
                                <p>
                                    <FormattedMessage id="status.dbh_max_desc" />
                                </p>
                            </Message>
                            <Row>
                                <Col>
                                {injectGetMessage((getMessage) => <Chart data={curr_status.dbh_classes} show_bar={false} display_bar_label={true}
                                     title={getMessage({ id: 'status.dbh_classes' })} xLabel={getMessage({ id: 'status.dbh_classes' })} yLabel={getMessage({ id: 'Percentage' })}/>)}
                                </Col>
                                <Col>
                                {injectGetMessage((getMessage) => <Chart data={curr_status.old_trees_by_group} show_bar={false} display_bar_label={true}
                                     title={getMessage({ id: 'status.old_trees_func' })} xLabel={getMessage({ id: 'func_group_abr' })} yLabel={getMessage({ id: 'Nb d\'arbres' })}/>)}

                                </Col>


                            </Row>
                            <Row>
                                <Col>
                                    <Table data={curr_status.old_trees_table} />
                                </Col>
                            </Row>
                        </Container>

                    )
                },
                {
                    name: intl.formatMessage({ id: 'status.ornement_value' }), content: (
                        <Container fluid={true}>
                            <SummaryBoxRow
                                dataBox1={curr_status.number_trees}
                                titleBox1={<FormattedMessage id="status.nb_trees" />}
                                dataBox2={curr_status.total_replacement_value.toFixed(2)}
                                titleBox2={<FormattedMessage id="status.tot_rep_value" />}
                            />
                            <span>&nbsp;</span>
                            <Message info>
                                <Message.Header><FormattedMessage id="status.rep_value" /></Message.Header>
                                <p>
                                    <FormattedMessage id="status.rep_value_desc" />
                                </p>
                            </Message>
                            <Row>
                                <Col>
                                {injectGetMessage((getMessage) => <Chart data={curr_status.replacement_value_by_species}
                                     title={getMessage({ id: 'status.rep_value_stor_sp' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'val' })}/>)}
                                </Col>
                                <Col>
                                    <Table data={curr_status.replacement_value_table} />
                                </Col>
                            </Row>

                        </Container>
                    )
                },
                {
                    name: intl.formatMessage({ id: 'status.carbon_storage' }), content: (
                        <Container fluid={true}>
                            <SummaryBoxRow
                                dataBox1={curr_status.number_trees}
                                titleBox1={<FormattedMessage id="status.nb_trees" />}
                                dataBox2={curr_status.total_carbon_storage.toFixed(2)}
                                titleBox2={<FormattedMessage id="status.tot_carb" />}
                                dataBox3={(curr_status.total_value).toFixed(2)}
                                titleBox3={<FormattedMessage id="status.total_val" />}
                            />
                            <span>&nbsp;</span>
                            <Message info>
                                <Message.Header><FormattedMessage id="settings.carbon_storage" /></Message.Header>
                                <p>
                                    <FormattedMessage id="status.carbon_storage_desc"/>
                                    <a href="https://www.canada.ca/fr/environnement-changement-climatique/services/changements-climatiques/recherche-donnees/cout-social-ges.html#toc7" target="_blank">(ECCC, 2023)</a>.
                                </p>
                            </Message>
                            <Row>
                                <Col>

                                    {injectGetMessage((getMessage) =>  <Chart data={curr_status.carbon_storage_by_species}
                                     title={getMessage({ id: 'status.carb_stor_sp' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'val' })}/>)}
                                        {/* <FormattedMessage id="status.carb_stor_sp">
                                            {(title) =>
                                                <Chart data={curr_status.carbon_storage_by_species} title={title} />
                                            }
                                        </FormattedMessage> */}

                                </Col>
                                <Col>
                                {injectGetMessage((getMessage) =>  <Chart data={curr_status.value_by_species} 
                                     title={getMessage({ id: 'status.val_sp' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'val' })}/>)}
                                    <div>
                                        {/* <FormattedMessage id="status.val_sp">
                                            {(title) =>
                                                <Chart data={curr_status.value_by_species} title={title} />
                                            }
                                        </FormattedMessage> */}
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Table data={curr_status.carbon_table} />
                                </Col>
                            </Row>

                        </Container>
                    )
                },
                // {
                //     name: "Valeur du coût social du carbone", content: (
                //         <Container fluid={true}>
                //             <SummaryBoxRow
                //                 dataBox1={curr_status.number_trees}
                //                 titleBox1={<FormattedMessage id="status.nb_trees" />}
                //                 // dataBox2={'$' + (curr_status.total_value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                //                 dataBox2={(curr_status.total_value).toFixed(2)}
                //                 titleBox2={<FormattedMessage id="status.total_val" />}
                //             />
                //             <span>&nbsp;</span>
                //             <Row>
                //                 <Col>
                //                     <div>
                //                         <FormattedMessage id="status.val_sp">
                //                             {(title) =>
                //                                 <Chart data={curr_status.value_by_species} title={title} />
                //                             }
                //                         </FormattedMessage>
                //                     </div>
                //                 </Col>
                //                 <Col>
                //                     <Table data={curr_status.carbon_table} />
                //                 </Col>
                //             </Row>
                //         </Container>
                //     )
                // },
            ];
        }
        else {
            data = [

                // { name: "Info. générales", content: <LoadingSpinner /> },
                { name: intl.formatMessage({id: "status.func_groups"}), content: <LoadingSpinner /> },
                { name: intl.formatMessage({id: "status.santamour"}), content: <LoadingSpinner /> },
                { name: intl.formatMessage({id: "status.species"}), content: <LoadingSpinner /> },
                { name: intl.formatMessage({id: "status.dhp"}), content: <LoadingSpinner /> },
                { name: intl.formatMessage({id: "status.ornement_value"}), content: <LoadingSpinner /> },
                { name: intl.formatMessage({id: "status.carbon_storage"}), content: <LoadingSpinner /> },
                // { name: "Valeur du coût social du carbone", content: <LoadingSpinner /> },
            ];

        }
        const panes = data.map(d => ({
            menuItem: d.name,
            render: () => <Tab.Pane> {d.content}</Tab.Pane>
        }));
        console.log(stats)
        return (

            <React.Fragment >
                {/* <HeaderMenu /> */}
                <div>
                    {/* Breadcrumb navigation to navigate between pages*/}
                    <div className="ui breadcrumb">
                        <Link to="/"><FormattedMessage id="status.sel_area" /></Link>
                        <div className="divider"> / </div>
                        <div className="active section"><FormattedMessage id="status.curr_status" /></div>
                    </div>


                    <Header textAlign='center' as='h2' className="noMargin" ><FormattedMessage id="status.curr_status" /></Header>
                    <Segment>
                        <Statistic.Group widths='three' size='tiny'>
                            <Statistic >
                                <Statistic.Value>{stats.trees}</Statistic.Value>
                                <Statistic.Label><FormattedMessage id="status.trees" /></Statistic.Label>
                            </Statistic>

                            <Statistic size='tiny'>
                                <Statistic.Value>{stats.old_trees}</Statistic.Value>
                                <Statistic.Label><FormattedMessage id="status.old_trees" /></Statistic.Label>
                            </Statistic>

                            <Statistic size='tiny'>
                                <Statistic.Value>{stats.trees_ha}</Statistic.Value>
                                <Statistic.Label><FormattedMessage id="status.trees_ha" /></Statistic.Label>
                            </Statistic>
                        </Statistic.Group>
                    </Segment>

                    {/* Tab Pane */}
                    <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />

                    {/* Grid for positioning two buttons */}

                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column textAlign='right' width='8'>

                                <Form onSubmit={this.handleSubmit.bind(this)} >
                                    <Button className="green ui button" block="false" ><FormattedMessage id="status.plant_new_trees" /></Button>

                                </Form>
                            </Grid.Column>
                            <Grid.Column textAlign='center' width='4'>
                                <Button className="btn-primary green ui button" block="false" disabled><FormattedMessage id="status.replace_trees" /></Button>
                            </Grid.Column>
                            <Grid.Column textAlign='left' width='4'>
                                {curr_status !== undefined &&
                                    <ExcelFile filename={this.getFileName()} element={<Button className="green ui button" block="false" ><FormattedMessage id="download_report" /></Button>}>
                                        <ExcelSheet data={curr_status.species_table} name="species">
                                            {Object.keys(curr_status.species_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={curr_status.genus_table} name="genus">
                                            {Object.keys(curr_status.genus_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={curr_status.family_table} name="families">
                                            {Object.keys(curr_status.family_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={curr_status.func_group_table} name="functional_groups">
                                            {Object.keys(curr_status.func_group_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={curr_status.replacement_value_table} name="replacement_value">
                                            {Object.keys(curr_status.replacement_value_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={curr_status.carbon_table} name="carbon_storage">
                                            {Object.keys(curr_status.carbon_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                    </ExcelFile>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </div>
                {/* <FooterMenu/>   */}
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {
    return {
        curr_status: state.curr_status,
    }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Status)));
