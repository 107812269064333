import React from "react";

// import ReactPDF, {
//     Document,
//     Page,
//     Text,
//     View,
//     StyleSheet,
//     Font,
//     Svg,
//     Image,
//   } from '../../dist/react-pdf.es.js';

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Link,
    Image,
    Canvas,
} from "@react-pdf/renderer";
import ReactDOM from 'react-dom';
import { VictoryBar } from 'victory';
// import moment from "moment";

import { renderToStaticMarkup } from 'react-dom/server';
import htmlToImage from 'html-to-image';


const mydata = [
    ['Species', 'Number of trees', 'Carbon storage', 'Value'],
    ['ACFR', 8, 69.264, 1579.214],
    ['GLTR', 19, 528.309, 12045.449]
]

const sanitize_block = (block) => {
    if (typeof (block) === 'string' || typeof (block) === "number") {
        return <Text>{block}</Text>
    } else {
        return block
    }
}

const Table = ({ children }) => (
    <View
        style={{
            marginTop: 8,
            marginBottom: 8,
            borderTop: "1 solid #DFDFDF",
            borderLeft: "1 solid #DFDFDF",
            display: "flex",
            flexDirection: "column"
        }}
    >
        {children}
    </View>
);


const TableRow = ({ children }) => (
    <View
        style={{
            display: "flex",
            flexDirection: "row",
            textAlign: "center"
        }}
    >
        {children}
    </View>
);

const TableCell = ({ children }) => (
    <View
        style={{
            flex: 1,
            fontSize: 12,
            borderRight: "1 solid #DFDFDF",
            borderBottom: "1 solid #DFDFDF"
        }}
    >
        <Text>{children}</Text>
    </View>
);

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        flexDirection: 'row',
    },
    section: {
        margin: 5,
        padding: 5,
        flexGrow: 1,
        width: '45%',
    },
    title: {
        margin: 10,
        fontSize: 24,
        textAlign: 'center',
        backgroundColor: '#ffffff',
        textTransform: 'uppercase',
    },

    subtitle: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: 150,
        textAlign: 'center',
        alignItems: "center",
        marginBottom: 10
    },
    text: {
        margin: 5,
        fontSize: 14,
        textAlign: 'justify',
    },
    body: {
        flexGrow: 1,
    },
    header: {
        fontSize: 14,
        textAlign: 'center',
        color: 'white',
        backgroundColor: '#DFDFDF',
        flexDirection: "row",
        padding: 5,
    },
    subheader: {
        fontSize: 12,
        marginBottom: 20,
        padding: 5,
        textAlign: 'left',
        color: 'white',
        backgroundColor: '#21ba45',
        flexDirection: "row",
    },
    row: {
        flexGrow: 1,
        flexDirection: 'row',
    },


    fill1: {
        width: '40%',
        backgroundColor: '#e14427',
    },
    fill2: {
        flexGrow: 2,
        backgroundColor: '#e6672d',
    },
    fill3: {
        flexGrow: 2,
        backgroundColor: '#e78632',
    },
    fill4: {
        flexGrow: 2,
        backgroundColor: '#e29e37',
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    movieContainer: {
        backgroundColor: "#f6f6f5",
        display: "flex",
        flexDirection: "row",
        padding: 5
    },
    movieDetails: {
        display: "flex",
        marginLeft: 5
    },
    movieTitle: {
        fontSize: 15,
        marginBottom: 10
    },
    movieOverview: {
        fontSize: 10
    },

    image: {
        height: 200,
        width: 150
    },

    vote: {
        display: "flex",
        flexDirection: "row"
    },
    rating: {
        height: 10,
        width: 10
    },
    vote_text: {
        fontSize: 10
    },
    vote_pop: {
        fontSize: 10,
        padding: 2,
        backgroundColor: "#61C74F",
        color: "#fff"
    },
    vote_pop_text: {
        fontSize: 10,
        marginLeft: 4
    },
    overviewContainer: {
        minHeight: 110
    },
    detailsFooter: {
        display: "flex",
        flexDirection: "row"
    },
    lang: {
        fontSize: 8,
        fontWeight: 700
    },
    vote_average: {
        fontSize: 8,
        marginLeft: 4,
        fontWeight: "bold"
    },
    circle: {
        width: 500,
        height: 500,
    },
});

export function PdfDocument(props) {
    // console.log("pdf props", props.graphs);
    // console.log("pdf props", Object.keys(props.graphs));
    // console.log("props received pdf", props)
    let charts = {}
    let oldStatus = props.oldStatus
    let newStatus = props.newStatus
    if (Object.keys(props.graphs).length > 0)
    // if (props.data.lenght>0)
    {
        charts =  props.graphs;
        console.log(charts.count_by_species_old)
    }
    else{
        charts = {'count_by_species_old': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAACnklEQVR4nO3csYkDQRREQcWi2MRFtM5FovBGERztSNwDVUG7ay2Pb83tkHID/nbOOfef37ftcT2/6nvvnGDBIFidCRYMgtWZYMEgWJ0JFgyC1ZlgwSBYnQkWDILVmWDBIFidCRYMgtWZYMEgWJ0JFgyC1ZlgwSBYnQkWDILVmWDBIFidCRYMgtWZYMEgWJ0JFgyC1ZlgwSBYnQkWDILVmWDBcM45j+tpgQkWDC6szgQLBsHqTLBgEKzOBAsGwepMsGAQrM4ECwbB6kywYBCszgQLBsHqTLBgEKzOBAsGwepMsGAQrM4ECwbB6kywYBCszgQLBsHqTLBgEKzOBAsGwepMsGAQrM4ECwbB6kywYBCszgQLBm+6dyZYMLiwOhMsGASrM8GCQbA6EywYBKszwYJBsDoTLBgEqzPBgkGwOhMsGASrM8GCQbA6EywYBKszwYJBsDoTLBgEqzPBgkGwOhMsGASrM8GCQbA6EywYBKszwYJBsDoTLBgEqzPBgkGwOhMsGLzp3plgweDC6kywYBCszgQLBsHqTLBgEKzOBAsGwepMsGAQrM4ECwbB6kywYBCszgQLBsHqTLBgEKzOBAsGwepMsGAQrM4ECwbB6kywYBCszgQLBsHqTLBgEKzOBAsGwepMsGAQrM4ECwbB6kywYBCszgQLBsHqTLBgEKzOBAsGwepMsGAQrM4ECwbB6kywYBCszgQLBsHqTLBgEKzOBAsGwepMsGAQrM4ECwbB6kywYBCszgQLBsHqTLBgEKzOBAsGwepMsGAQrM4ECwbB6kywYBCszgQLhkPKf/8PAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMAnvQDdeCiUQeyEygAAAABJRU5ErkJggg=='}
    }
    return (
        <Document>
            <Page size="A4">

                <View style={styles.body}>
                    {/* <Image style={styles.logo} src={require('../images/logo.png')} /> */}
                    <Text style={styles.header} fixed>
                        SylvCiT report
                    </Text>
                    <Text style={styles.subheader} fixed>
                        General
                    </Text>
                    <Text style={styles.title}>
                        Status after planting new trees
                    </Text>
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                Before
                        </Text>
                            <Text style={styles.text}>
                                Number of trees : {oldStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                                Species Richness : {oldStatus.species_richness}
                        </Text>
                            <Text style={styles.text}>
                                Species Diversity : {oldStatus.species_diversity.toFixed(2)}
                        </Text>
                        <Image allowDangerousPath source={{ uri: charts.top_species_old}}/>
                        <Table>
                            <TableRow key={0}>
                            {
                                (Object.keys(oldStatus.species_table[0])).map((cell, col_index) =>
                                    <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                )
                            }
                            </TableRow>
                                {
                                    oldStatus.species_table.map(
                                        (row, row_index) =>
                                            <TableRow key={row_index+1}>
                                                {
                                                    (Object.values(row)).map((cell, col_index) =>
                                                        <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                    )
                                }
                            </Table>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                After
                        </Text>
                            <Text style={styles.text}>
                                Number of trees : {newStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                                Species Richness : {newStatus.species_richness}
                        </Text>
                            <Text style={styles.text}>
                                Species Diversity : {newStatus.species_diversity.toFixed(2)}
                        </Text>
                            <Image allowDangerousPath source={{ uri: charts.top_species_new}}/>
                            <Table>
                            <TableRow key={0}>
                            {
                                (Object.keys(newStatus.species_table[0])).map((cell, col_index) =>
                                    <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                )
                            }
                            </TableRow>
                                {
                                    newStatus.species_table.map(
                                        (row, row_index) =>
                                            <TableRow key={row_index+1}>
                                                {
                                                    (Object.values(row)).map((cell, col_index) =>
                                                        <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                    )
                                }
                            </Table>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4">

                <View style={styles.body}>
                    {/* <Image style={styles.logo} src={require('../images/logo.png')} /> */}
                    <Text style={styles.header} fixed>
                        SylvCiT report
                    </Text>
                    <Text style={styles.subheader} fixed>
                        Species
                    </Text>
                    <Text style={styles.title}>
                        Status after planting new trees
                    </Text>
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                Before
                        </Text>
                            <Text style={styles.text}>
                                Species Richness: {oldStatus.species_richness}
                        </Text>
                            <Text style={styles.text}>
                                Species Diversity: {oldStatus.species_diversity.toFixed(2)}
                        </Text>
                        <Image allowDangerousPath source={{ uri: charts.count_by_species_old}}/>
                            {/* <Image source={{ uri: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAwBQTFRF7c5J78kt+/Xm78lQ6stH5LI36bQh6rcf7sQp671G89ZZ8c9V8c5U9+u27MhJ/Pjv9txf8uCx57c937Ay5L1n58Nb67si8tVZ5sA68tJX/Pfr7dF58tBG9d5e8+Gc6chN6LM+7spN1pos6rYs6L8+47hE7cNG6bQc9uFj7sMn4rc17cMx3atG8duj+O7B686H7cAl7cEm7sRM26cq/vz5/v767NFY7tJM78Yq8s8y3agt9dte6sVD/vz15bY59Nlb8txY9+y86LpA5LxL67pE7L5H05Ai2Z4m58Vz89RI7dKr+/XY8Ms68dx/6sZE7sRCzIEN0YwZ67wi6rk27L4k9NZB4rAz7L0j5rM66bMb682a5sJG6LEm3asy3q0w3q026sqC8cxJ6bYd685U5a457cIn7MBJ8tZW7c1I7c5K7cQ18Msu/v3678tQ3aMq7tNe6chu6rgg79VN8tNH8c0w57Q83akq7dBb9Nld9d5g6cdC8dyb675F/v327NB6////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/LvB3QAAAMFJREFUeNpiqIcAbz0ogwFKm7GgCjgyZMihCLCkc0nkIAnIMVRw2UhDBGp5fcurGOyLfbhVtJwLdJkY8oscZCsFPBk5spiNaoTC4hnqk801Qi2zLQyD2NlcWWP5GepN5TOtSxg1QwrV01itpECG2kaLy3AYiCWxcRozQWyp9pNMDWePDI4QgVpbx5eo7a+mHFOqAxUQVeRhdrLjdFFQggqo5tqVeSS456UEQgWE4/RBboxyC4AKCEI9Wu9lUl8PEGAAV7NY4hyx8voAAAAASUVORK5CYII=' }} /> */}
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                After
                        </Text>
                            <Text style={styles.text}>
                            Species Richness: {newStatus.species_richness}
                        </Text>
                            <Text style={styles.text}>
                            Species Diversity: {newStatus.species_diversity.toFixed(2)}
                        </Text>
                            <Image allowDangerousPath source={{ uri: charts.count_by_species_new}}/>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4">

                <View style={styles.body}>
                    {/* <Image style={styles.logo} src={require('../images/logo.png')} /> */}
                    <Text style={styles.header} fixed>
                        SylvCiT report
                    </Text>
                    <Text style={styles.subheader} fixed>
                        Functional Group
                    </Text>
                    <Text style={styles.title}>
                        Status after planting new trees
                    </Text>
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                Before
                        </Text>
                        <Image allowDangerousPath source={{ uri: charts.count_by_func_group_old}}/>
                        <Table>
                            <TableRow key={0}>
                            {
                                (Object.keys(oldStatus.func_group_table[0])).map((cell, col_index) =>
                                    <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                )
                            }
                            </TableRow>
                                {
                                    oldStatus.func_group_table.map(
                                        (row, row_index) =>
                                            <TableRow key={row_index+1}>
                                                {
                                                    (Object.values(row)).map((cell, col_index) =>
                                                        <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                    )
                                }
                            </Table>
                            
                        </View>
                        <View style={styles.section}>
                        <Text style={styles.subtitle}>
                                After
                        </Text>
                        <Image allowDangerousPath source={{ uri: charts.count_by_func_group_new}}/>
                        <Table>
                            <TableRow key={0}>
                            {
                                (Object.keys(newStatus.func_group_table[0])).map((cell, col_index) =>
                                    <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                )
                            }
                            </TableRow>
                                {
                                    newStatus.func_group_table.map(
                                        (row, row_index) =>
                                            <TableRow key={row_index+1}>
                                                {
                                                    (Object.values(row)).map((cell, col_index) =>
                                                        <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                    )
                                }
                            </Table>
                            
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4">

                <View style={styles.body}>
                    {/* <Image style={styles.logo} src={require('../images/logo.png')} /> */}
                    <Text style={styles.header} fixed>
                        SylvCiT report
                    </Text>
                    <Text style={styles.subheader} fixed>
                        Genus
                    </Text>
                    <Text style={styles.title}>
                        Status after planting new trees
                    </Text>
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                Before
                        </Text>
                            <Image allowDangerousPath source={{ uri: charts.count_by_genus_old}}/>
                            <Table>
                            <TableRow key={0}>
                            {
                                (Object.keys(oldStatus.genus_table[0])).map((cell, col_index) =>
                                    <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                )
                            }
                            </TableRow>
                                {
                                    oldStatus.genus_table.map(
                                        (row, row_index) =>
                                            <TableRow key={row_index+1}>
                                                {
                                                    (Object.values(row)).map((cell, col_index) =>
                                                        <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                    )
                                }
                            </Table>
                            
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                After
                        </Text>
                        <Image allowDangerousPath source={{ uri: charts.count_by_genus_new}}/>
                        <Table>
                            <TableRow key={0}>
                            {
                                (Object.keys(newStatus.genus_table[0])).map((cell, col_index) =>
                                    <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                )
                            }
                            </TableRow>
                                {
                                    newStatus.genus_table.map(
                                        (row, row_index) =>
                                            <TableRow key={row_index+1}>
                                                {
                                                    (Object.values(row)).map((cell, col_index) =>
                                                        <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                    )
                                }
                            </Table>
                            
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4">

                <View style={styles.body}>
                    {/* <Image style={styles.logo} src={require('../images/logo.png')} /> */}
                    <Text style={styles.header} fixed>
                        SylvCiT report
                    </Text>
                    <Text style={styles.subheader} fixed>
                        Family
                    </Text>
                    <Text style={styles.title}>
                        Status after planting new trees
                    </Text>
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                Before
                        </Text>
                        <Image allowDangerousPath source={{ uri: charts.count_by_family_old}}/>
                        <Table>
                            <TableRow key={0}>
                            {
                                (Object.keys(oldStatus.family_table[0])).map((cell, col_index) =>
                                    <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                )
                            }
                            </TableRow>
                                {
                                    oldStatus.family_table.map(
                                        (row, row_index) =>
                                            <TableRow key={row_index+1}>
                                                {
                                                    (Object.values(row)).map((cell, col_index) =>
                                                        <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                    )
                                }
                            </Table>
                            
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                After
                        </Text>
                        <Image allowDangerousPath source={{ uri: charts.count_by_family_new}}/>
                        <Table>
                            <TableRow key={0}>
                            {
                                (Object.keys(newStatus.family_table[0])).map((cell, col_index) =>
                                    <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                )
                            }
                            </TableRow>
                                {
                                    newStatus.family_table.map(
                                        (row, row_index) =>
                                            <TableRow key={row_index+1}>
                                                {
                                                    (Object.values(row)).map((cell, col_index) =>
                                                        <TableCell key={col_index}>{sanitize_block(cell)}</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                    )
                                }
                            </Table>
                            
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4">

                <View style={styles.body}>
                    {/* <Image style={styles.logo} src={require('../images/logo.png')} /> */}
                    <Text style={styles.header} fixed>
                        SylvCiT report
                    </Text>
                    <Text style={styles.subheader} fixed>
                        Diversity
                    </Text>
                    <Text style={styles.title}>
                        Status after planting new trees
                    </Text>
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                Before
                        </Text>
                            <Text style={styles.text}>
                                Number of trees : {oldStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                                Func Group Diversity: {oldStatus.group_diversity.toFixed(2)}
                        </Text>
                            <Text style={styles.text}>
                                Species Diversity : {oldStatus.species_diversity.toFixed(2)}
                        </Text>
                        <Image allowDangerousPath source={{ uri: charts.count_by_species_old}}/>
                            
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                After
                        </Text>
                            <Text style={styles.text}>
                                Number of trees : {newStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                                Func Group Diversity: {newStatus.group_diversity.toFixed(2)}
                        </Text>
                            <Text style={styles.text}>
                                Species Diversity : {newStatus.species_diversity.toFixed(2)}
                        </Text>
                        <Image allowDangerousPath source={{ uri: charts.count_by_species_old}}/>
                            
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4">

                <View style={styles.body}>
                    {/* <Image style={styles.logo} src={require('../images/logo.png')} /> */}
                    <Text style={styles.header} fixed>
                        SylvCiT report
                    </Text>
                    <Text style={styles.subheader} fixed>
                        Richness
                    </Text>
                    <Text style={styles.title}>
                        Status after planting new trees
                    </Text>
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                Before
                        </Text>
                            <Text style={styles.text}>
                                Number of trees : {oldStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                                Func Group Richness: {oldStatus.group_richness}
                        </Text>
                            <Text style={styles.text}>
                                Species Richness: {oldStatus.species_richness}
                        </Text>
                           
                            <Image allowDangerousPath source={{ uri: charts.count_by_species_old}}/>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                After
                        </Text>
                            <Text style={styles.text}>
                                Number of trees : {newStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                                Func Group Richness: {newStatus.group_richness}
                        </Text>
                            <Text style={styles.text}>
                            Species Richness: {newStatus.species_richness}
                        </Text>
                           
                            <Image allowDangerousPath source={{ uri: charts.count_by_species_old}}/>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4">

                <View style={styles.body}>
                    {/* <Image style={styles.logo} src={require('../images/logo.png')} /> */}
                    <Text style={styles.header} fixed>
                        SylvCiT report
                    </Text>
                    <Text style={styles.subheader} fixed>
                        Carbon Storage
                    </Text>
                    <Text style={styles.title}>
                        Status after planting new trees
                    </Text>
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                Before
                        </Text>
                            <Text style={styles.text}>
                                Number of trees : {oldStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                            Total Carbon Storage: {oldStatus.total_carbon_storage.toFixed(2)} kg
                        </Text>
                            <Image allowDangerousPath source={{ uri: charts.carbon_storage_by_species_old}}/>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                After
                        </Text>
                            <Text style={styles.text}>
                                Number of trees : {newStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                            Total Carbon Storage: {newStatus.total_carbon_storage.toFixed(2)} kg
                        </Text>
                            
                            <Image allowDangerousPath source={{ uri: charts.carbon_storage_by_species_new}}/>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4">

                <View style={styles.body}>
                    {/* <Image style={styles.logo} src={require('../images/logo.png')} /> */}
                    <Text style={styles.header} fixed>
                        SylvCiT report
                    </Text>
                    <Text style={styles.subheader} fixed>
                        Value
                    </Text>
                    <Text style={styles.title}>
                        Status after planting new trees
                    </Text>
                    <View style={styles.row}>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                Before
                        </Text>
                            <Text style={styles.text}>
                                Number of trees : {oldStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                            Total Value: $ {oldStatus.total_value.toFixed(2)}
                        </Text>
                            <Image allowDangerousPath source={{ uri: charts.value_by_species_old}}/>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.subtitle}>
                                After
                        </Text>
                            <Text style={styles.text}>
                                Number of trees :{newStatus.number_trees}
                        </Text>
                            <Text style={styles.text}>
                            Total Value: $ {newStatus.total_value.toFixed(2)}
                        </Text>

                            <Image allowDangerousPath source={{ uri: charts.value_by_species_new}}/>
                        </View>
                    </View>
                </View>
            </Page>

        </Document>
    );
}
