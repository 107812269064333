import React from "react";
import {useIntl, FormattedMessage} from "react-intl";

const TeamSection = () => {
  const intl = useIntl();
  const url = intl.formatMessage({id: "guide_url"});

  return (
    <section id="team" className="team">
      <div className="container" data-aos="fade-up">
        <h2><FormattedMessage id="research.title" /></h2>
        <p>
          <FormattedMessage id="research.paragraph" />
        </p>
        <div className="row gy-3">
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={100}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_christian.gif" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_1"/></h4>
                <span><FormattedMessage id="research.team_member_1_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_1_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_1_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
                <a href={intl.formatMessage({id: "research.team_member_1_url_2"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={200}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_mariejean.jpg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_2"/></h4>
                <span><FormattedMessage id="research.team_member_2_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_2_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_2_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={300}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_annick.jpg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_3"/></h4>
                <span><FormattedMessage id="research.team_member_3_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_3_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_3_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={400}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_joel.jpg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_4"/></h4>
                <span><FormattedMessage id="research.team_member_4_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_4_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_4_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={400}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_fanny.jpg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_5"/></h4>
                <span><FormattedMessage id="research.team_member_5_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_5_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_5_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={400}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_tanya.jpg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_6"/></h4>
                <span><FormattedMessage id="research.team_member_6_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_6_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_6_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={400}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_sylvain.jpg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_7"/></h4>
                <span><FormattedMessage id="research.team_member_7_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_7_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_7_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={400}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_maxime.jpg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_8"/></h4>
                <span><FormattedMessage id="research.team_member_8_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_8_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_8_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={400}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_samy.jpg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_9"/></h4>
                <span><FormattedMessage id="research.team_member_9_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_9_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_9_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay={400}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_etienne.jpeg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_10"/></h4>
                <span><FormattedMessage id="research.team_member_10_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_10_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_10_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
          <div
              className="col-lg-3 col-md-6 d-flex align-items-stretch center"
              data-aos="fade-up"
              data-aos-delay={400}
          >
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team_camille.jpg" className="img-fluid"/>
              </div>
              <div className="member-info">
                <h4><FormattedMessage id="research.team_member_11"/></h4>
                <span><FormattedMessage id="research.team_member_11_title"/></span>
                <div className="readmore pull-right">
                  <a className="pull-right" target="_blank">Voir plus</a>
                </div>
                <p>
                  <FormattedMessage id="research.team_member_11_description"/>
                </p>
                <a href={intl.formatMessage({id: "research.team_member_11_url"})} target="_blank"><i
                    className="teamlink bi bi-link"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeamSection;
