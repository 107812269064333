import React from "react";

const HeaderLogo = () => (
  <a href="/" className="logo d-flex align-items-center">
    <img src="assets/img/logo.png" alt="logo sylvict" />
    <span>SylvCiT</span>
  </a>
);

export default HeaderLogo;
