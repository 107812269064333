import React from "react";
import {FormattedMessage} from "react-intl";

const MediaSection = () => (
  <section id="medias" className="medias">
    <div className="container" data-aos="fade-up">
      <h2><FormattedMessage id="media.title" /></h2>
      <div className="row">
        <div className="col-lg-4">
          <div className="post-box">
            <div className="post-img">
              <img
                  src="assets/img/medias/media-10.jpg"
                  className="img-fluid"
              />
            </div>
            <span className="post-date">24 Juillet 2024</span>
            <h3 className="post-title">
              Montréal veut inciter le secteur privé à planter plus d’arbres
            </h3>
            <a
                href="https://ici.radio-canada.ca/nouvelle/2090242/montreal-planter-arbres-secteur-prive"
                target="_blank"
                className="readmore stretched-link mt-auto"
            >
              <span>
                <FormattedMessage id="see_more"/>
              </span>
            </a>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="post-box">
            <div className="post-img">
              <img
                  src="assets/img/medias/media-9bis.jpg"
                  className="img-fluid"
              />
            </div>
            <span className="post-date">20 février 2024</span>
            <h3 className="post-title">
              À la recherche des meilleures solutions pour la santé des arbres
            </h3>
            <a
                href="https://www.lapresse.ca/dialogue/opinions/2024-02-20/replique-hydro-quebec-et-l-abattage/a-la-recherche-des-meilleures-solutions-pour-la-sante-des-arbres.php"
                target="_blank"
                className="readmore stretched-link mt-auto"
            >
              <span>
                <FormattedMessage id="see_more"/>
              </span>
            </a>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="post-box">
            <div className="post-img">
              <img
                  src="assets/img/medias/media-1.jpg"
                  className="img-fluid"
              />
            </div>
            <span className="post-date">08 Mars 2023</span>
            <h3 className="post-title">
              Boucherville et Varennes participent à une étude sur la foresterie
              urbaine
            </h3>
            <a
                href="https://www.lareleve.qc.ca/2023/03/08/boucherville-et-varennes-participent-a-une-etude-sur-la-foresterie-urbaine/"
                target="_blank"
                className="readmore stretched-link mt-auto"
            >
              <span><FormattedMessage id="see_more"/></span>
            </a>
          </div>
        </div>
      </div>
      <div className="text-center text-lg-center">
        <a
            href="/medias"
            className="btn scrollto d-inline-flex align-items-center justify-content-center align-self-center"
        >
          <span><FormattedMessage id="media.all_media"/></span>
        </a>
      </div>
    </div>
  </section>
);

export default MediaSection;
