import React from "react";
import HeaderLogo from "./HeaderLogo";
import NavBar from "./NavBar";
import PropTypes from "prop-types";

const Header = (props) => {
  return (
    <header id="header" className="header">
      <div className="container-fluid container-sm d-flex align-items-center justify-content-between">
        <HeaderLogo></HeaderLogo>
        <NavBar onLanguageChange={props.onLanguageChange}></NavBar>
      </div>
    </header>
  );
}

export default Header;

Header.propTypes = {
  onLanguageChange: PropTypes.func.isRequired
}
