import AOS from "aos";
import "aos/dist/aos.css";
import React, {useEffect} from "react";
import IntroSection from "./IntroSection";
import Main from "./Main";
import Footer from "./Footer";
import {Helmet} from "react-helmet";

const HomePage = () => {
  return (
    <div>
      <IntroSection></IntroSection>
      <Main></Main>
      <Footer></Footer>
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
    </div>
  );
}

export default HomePage;