import React, { Component } from "react";
import axios from 'axios';
// import TreeMap from '../map'
import MapBox from '../components/App/mapboxHabitat'
import { connect } from 'react-redux';
import { storing_curr_status } from '../actions/actions';
import {withRouter} from "../utils/Routing";




class Habitat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_mode: null,
            numTrees: 0
        }
    }

    handleSubmit = event => {

        event.preventDefault();

        axios.post('http://localhost:8000/api/home', {
            id: this.props.index
        })
            .then(response => {
                console.log('Response data home' + response.data)
                this.props.dispatch(storing_curr_status(response.data));
            })
            .catch(error => {
                console.log(error);
            });
        this.props.navigate({
            pathname: '/app/status'
        });
    }

    handleChange(val) {
        console.log("Number of trees ...: " + val)
    }

    selectionModeOnChange = (e, { value }) => {
        e.persist();
        let selected_value = e.currentTarget.textContent
        console.log(selected_value);
        this.setState({ selected_mode: selected_value })
    };


    render() {
        const { numTrees } = this.state.numTrees;
        const { index } = this.props;

        return (
            // <TreeMap selectedIndex={index} selectedMode={this.state.selected_mode} numTrees={numTrees} onChange={this.handleChange} />
            <MapBox/>
        );
    }

}

const mapStateToProps = state => ({
    index: state.selected,
    curr_status: state.curr_status,
    district: state.district

});

export default connect(mapStateToProps)(withRouter(Habitat));
