import React from "react";
import {FormattedMessage} from "react-intl";

const AboutSection = () => (
  <section id="about" className="about">
    <div className="container" data-aos="fade-up">
      <div className="row gx-0">
        <div
          className="col-lg-6 d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="content">
            <h2><FormattedMessage id="about.title" /></h2>
            <p>
              <FormattedMessage id="about.first_paragraph" />
            </p>
            <p>
              <FormattedMessage id="about.second_paragraph" />
            </p>
            <p>
              <FormattedMessage id="about.third_paragraph" />
            </p>
          </div>
        </div>
        <div
          className="col-lg-6 d-flex align-items-center"
          data-aos="zoom-out"
          data-aos-delay={200}
        >
            <div className="image-credit-wrapper">
              <a href="https://comtois-etienne.com/" target="_blank" className="image-credit">Photo by Étienne Comtois</a>
              <img src="assets/img/about.jpg" className="img-fluid" alt="Image about"/>
            </div> 
        </div>
      </div>
    </div>
  </section>
);

export default AboutSection;
