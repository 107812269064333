import React, { Component } from 'react';
import Box from '../Box';
import formatMoney from '../../utils/format';
import { Tab, Header, Button, Grid, Form, Message, Segment,Statistic, Popup, Icon} from 'semantic-ui-react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FormattedMessage, FormattedNumber } from 'react-intl';


const SummaryBoxRow = (props) => {
  const {
    dataBox1,
    dataBox2,
    dataBox3,
    titleBox1,
    titleBox2,
    titleBox3,
    infoDataBox1,
    infoDataBox2,
    infoDataBox3,
    TitleInfoDataBox1,
    TitleInfoDataBox2,
    TitleInfoDataBox3,
    DescInfoDataBox1,
    DescInfoDataBox2,
    DescInfoDataBox3,
    emails,
    toBeShipped
  } = props;

  return (
    <div>
    <Row>
    <Col>
    <Box className="SummaryBox Box--white">
          <h2 className="SummaryBox-title">
  <strong>{titleBox1|| 0}</strong> 
  {infoDataBox1 ? 
  <Popup trigger={<Icon name='question circle' size='Medium' />}>
  <Popup.Header>{TitleInfoDataBox1}</Popup.Header>
  <Popup.Content>
      {DescInfoDataBox1}
  </Popup.Content>
</Popup>
  : null}
  
          </h2>

          <strong className="SummaryBox-value">
            {<FormattedNumber value={dataBox1}/> || 0}                                                
            
          </strong>

          <i className="SummaryBox-icon fa fa-users" />
        </Box>

    </Col>
    <Col>
        <Box className="SummaryBox Box--white">
          <h2 className="SummaryBox-title">
    <strong>{titleBox2|| 0}</strong>
    {infoDataBox2 ? 
  <Popup trigger={<Icon name='question circle' size='Medium' />}>
  <Popup.Header>{TitleInfoDataBox2}</Popup.Header>
  <Popup.Content>
      {DescInfoDataBox2}
  </Popup.Content>
</Popup>
  : null}
          </h2>
          
          <strong className="SummaryBox-value">
             {<FormattedNumber value={dataBox2}/> || 0}
          </strong>

          <i className="SummaryBox-icon fa fa-envelope" />
        </Box>
        </Col>
        
        {dataBox3 ?
        <Col>
        <Box className="SummaryBox Box--white">
        <h2 className="SummaryBox-title">
          <strong>{titleBox3|| 0}</strong>
          {infoDataBox3 ? 
  <Popup trigger={<Icon name='question circle' size='Medium' />}>
  <Popup.Header>{TitleInfoDataBox3}</Popup.Header>
  <Popup.Content>
      {DescInfoDataBox3}
  </Popup.Content>
</Popup>
  : null}
          </h2>
          
          <strong className="SummaryBox-value">
             {<FormattedNumber value={dataBox3}/> || 0}
          </strong>

          <i className="SummaryBox-icon fa fa-paper-plane" />
        </Box>
        </Col> : null
      }
    </Row>

    </div>
 
  );
};

// SummaryBoxRow.propTypes = {
//   orders: PropTypes.number,
//   profit: PropTypes.number,
//   emails: PropTypes.number,
//   toBeShipped: PropTypes.number
// };

export default SummaryBoxRow;