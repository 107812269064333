import React, { Component } from 'react'


export default class RatingFeatures extends Component {
  
  render() {
    return (
      <div className="features-rating-container">
        
        <input className="rating-divs" type='range' min={1} max={10} value={this.props.value} onChange={(e)=>this.props.onChange(e.target.value)} disabled={this.props.disabled} />
        <div className="rating-divs">{this.props.value}</div>
      </div>
    )
  }
}