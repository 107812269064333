import React from "react";
import {FormattedMessage} from "react-intl";

const OriginSection = () => (
  <section id="origines" className="origines">
    <div className="container" data-aos="fade-up">
      <h2><FormattedMessage id="origin.title" /></h2>
      <p>
        <b><FormattedMessage id="origin.project_name" /></b> <FormattedMessage id="origin.first_phrase" />{" "}
        <i><FormattedMessage id="origin.rdv_pile" /></i> <FormattedMessage id="origin.second_phrase" /> {" "}
      </p>
      <div className="row">
        <div className="col-lg-4" data-aos="fade-up" data-aos-delay={200}>
          <div className="box">
            <img
              src="assets/img/origine/origine-1.png"
              className="img-fluid"
            />
            <h3><FormattedMessage id="origin.pile_congrats" /></h3>
            <p>
              <FormattedMessage id="origin.pile_summary" />
            </p>
            <div className="readmore">
              <a href="https://rvpile.uqam.ca/" target="_blank">
                <FormattedMessage id="see_more" />
              </a>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay={400}
        >
          <div className="box">
            <img
              src="assets/img/origine/origine-2.jpg"
              className="img-fluid"
            />
            <h3>
              <FormattedMessage id="origin.pile_winners" />
            </h3>
            <p>
              <FormattedMessage id="origin.pile_projects_summary" />
            </p>
            <div className="readmore">
              <a
                href="https://actualites.uqam.ca/2017/rendez-vous-pile-les-gagnants/?fbclid=IwAR0jV87Dgu-mPPmrsCek1u2YaUnUgDR5mextFQ8NjEOn1-jvfR6Ky7CSDTU"
                target="_blank"
              >
                <FormattedMessage id="see_more" />
              </a>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay={600}
        >
          <div className="box">
            <img
              src="assets/img/origine/origine-3.jpg"
              className="img-fluid"
            />
            <h3><FormattedMessage id="origin.prof_title" /></h3>
            <p>
              <FormattedMessage id="origin.prof_summary" />
            </p>
            <div className="readmore">
              <a href="https://ise.uqam.ca/non-classe/page/3/" target="_blank">
                <FormattedMessage id="see_more" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default OriginSection;
