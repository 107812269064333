import React from "react";
import {FormattedMessage} from "react-intl";

const AppSection = () => (
  <section id="application" className="application">
    <div className="container" data-aos="fade-up">
      <h2><FormattedMessage id="app.title" /></h2>
      <p>
        <FormattedMessage id="app.first_paragraph" />
      </p>
      <p>
        <i>
          <FormattedMessage id="app.second_paragraph" />{" "}
          <a href="https://www.gnu.org/licenses/gpl-3.0.html" target="_blank">
            GNU GPL v3 license{" "}
          </a>
        </i>
      </p>
      <p>
        <i>
          <FormattedMessage id="app.accept_message" /> {" "}
          <a href="conditions-d'utilisation.html">
            <u><FormattedMessage id="app.terms_conditions" /></u>
          </a>
          .
        </i>
      </p>
      <div className="row content" data-aos="fade-up">
        <div className="col text-center text-lg-end">
          <a
            href="/app"
            className="btn scrollto d-inline-flex align-items-center justify-content-center align-self-center"
            target="_blank"
          >
            <i className="bi bi-tree-fill" />
            <span><FormattedMessage id="app.application" /> </span>
          </a>
        </div>
        <div className="col text-center text-lg-start">
          <a
            href="https://gitlab.com/ikb-lab/ikb-lab1/data-science/sylvcit"
            className="btn scrollto d-inline-flex align-items-center justify-content-center align-self-center"
            target="_blank"
          >
            <i className="bi bi-github" />
            <span><FormattedMessage id="app.source_code" /></span>
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default AppSection;
