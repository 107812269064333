import React from "react";
import AboutSection from "./AboutSection";
import GoalSection from "./GoalSection";
import OriginSection from "./OriginSection";
import TeamSection from "./TeamSection";
import AppSection from "./AppSection";
import PartnerSection from "./PartnerSection";
import MediaSection from "./MediaSection";
import Contact from "./Contact";

const Main = () => (
  <main id="main">
    <AboutSection></AboutSection>
    <GoalSection></GoalSection>
    <TeamSection></TeamSection>
    <AppSection></AppSection>
    <PartnerSection></PartnerSection>
    <MediaSection></MediaSection>
    <OriginSection></OriginSection>
    <Contact></Contact>
  </main>
);

export default Main;
