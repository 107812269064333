import React, { Component } from "react";
import { VictoryBar, VictoryChart, VictoryTheme, VictoryLabel, VictoryAxis, VictoryLine, VictoryContainer } from "victory";
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

const injectGetMessage = (fn) => 
  React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)));



class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            show_bar: false,
            display_bar_label: false,
            y_bar: 0,
            y2_bar: 0,
            padding_left:150,
            padding_bottom:80,
            graph_title: "Title",
            fixOverlap: true,
            xLabel:"xLabel",
            yLabel:"yLabel"
        }
    }

    //good place to do network requests when the component has been updated
    componentDidUpdate(prevProps, prevState) {
        // console.log('component did update')
        let data_plot = []

        // Typical usage (don't forget to compare props):
        if (this.props.data !== prevProps.data) {
            let data_to_plot = this.props.data
            // console.log('received' + data_to_plot)
            Object.keys(data_to_plot).map(function (key) {
                data_plot.push({ x: key, y: data_to_plot[key] })

            });
            this.setState({ data: data_plot })
            this.setState({ graph_title: this.props.title })
            this.setState({ show_bar: this.props.show_bar })
            this.setState({ y_bar: this.props.y_bar })
            this.setState({ y2_bar: this.props.y2_bar })
            this.setState({ fixOverlap: this.props.fixOverlap })
            this.setState({ display_bar_label: this.props.display_bar_label })
            this.setState({ padding_left:this.props.padding_left})
            this.setState({ padding_bottom:this.props.padding_bottom})
            this.setState({ xLabel:this.props.xLabel})
            this.setState({ yLabel:this.props.yLabel})
            

        }
    }
    componentDidMount() {
        // console.log('component did mount')
        // console.log(this.props.data)
        let data_plot = []
        // Typical usage (don't forget to compare props):

        let data_to_plot = this.props.data
        // console.log('received' + data_to_plot)
        Object.keys(data_to_plot).map(function (key) {
            data_plot.push({ x: key, y: data_to_plot[key] })
        });
        this.setState({ data: data_plot })
        this.setState({ graph_title: this.props.title })
        this.setState({ show_bar: this.props.show_bar })
        this.setState({ y_bar: this.props.y_bar })
        this.setState({ y2_bar: this.props.y2_bar })
        this.setState({ fixOverlap: this.props.fixOverlap })
        this.setState({ display_bar_label: this.props.display_bar_label })
        this.setState({ padding_left:this.props.padding_left})
        this.setState({ padding_bottom:this.props.padding_bottom})
        this.setState({ xLabel:this.props.xLabel})
        this.setState({ yLabel:this.props.yLabel})
    }
    colorSwitcher(data) {
        // console.log(data)
        // console.log(data.datum.x)
        switch (data.datum.x) {
            case '1A':
                return '#9BBB59';
            case '1B':
                return '#D7E4BD';
            case '2A':
                return '#376092';
            case '2B':
                return '#4F81BD';
            case '2C':
                return '#B9CDE5';
            case '3A':
                return '#F79646';
            case '3B':
                return '#FCD5B5';
            case '4A':
                return '#FFBF00';
            case '4B':
                return '#FFE79A';
            case '5A':
                return '#FFFFCC';
            default:
                return '#33b716';
        }
    }

    render() {
        // console.log(this.state.graph_title)
        return (
            <VictoryChart
                style={{ parent: { width: 620, height: 500 } }}
                width={550}
                padding={{ left: this.state.padding_left||150, bottom: this.state.padding_bottom||80, top: 20 }}
                // domainPadding will add space to each side of VictoryBar to
                // prevent it from overlapping the axis
                domainPadding={40}

                // adding the material theme provided with Victory
                theme={VictoryTheme.material}
                standalone={true}
            // containerRef={(ref) => {
            //     this.containerRef = ref;

            //     setTimeout(() => {
            //       this.containerRef.svgRef.toDataURL((base64) => {
            //         console.log(base64);
            //       });
            //     }, 500);
            //   }}
            >
                {this.state.show_bar ?
                    <VictoryLine y={() => this.state.y_bar}
                        style={{
                            data: { stroke: "#c43a31" },
                            parent: { border: "1px solid #c43a31" }
                        }} />
                    : null}
                {this.state.show_bar ?
                    <VictoryLine y={() => this.state.y2_bar}
                                 style={{
                                     data: { stroke: "#ceb538" },
                                     parent: { border: "1px solid #c43a31" }
                                 }} />
                    : null}
                <VictoryLabel x={490} y={10}
                    transform="skewX(0)"
                    textAnchor={"end"}
                    text={this.state.graph_title}
                />
                {/* Y Axis */}
                <VictoryAxis
                    fixLabelOverlap
                    label={this.state.yLabel}
                    orientation="bottom"
                    style={{
                        axis: { stroke: '#000' },
                        axisLabel: {fontSize: 14, padding: 30},
                        ticks: { stroke: '#000' },
                    }} dependentAxis
                />
                {/* X Axis */}
                <VictoryAxis
                    tickLabelComponent={<VictoryLabel renderInPortal transform="skewX(0)" />}
                    orientation="left"
                    label={this.state.xLabel}
                    fixLabelOverlap={this.state.fixOverlap}
                    axisLabelComponent={<VictoryLabel dy={-115} />}
                    style={{
                        axis: { stroke: '#000' },
                        ticks: { stroke: '#000' },
                        axisLabel: {fontSize: 14, angle: 0, padding : 50},
                        
                        tickLabels: {
                            fontSize: 11,
                            padding: 10,
                            verticalAnchor: "middle",
                            textAnchor: 'end'
                        }
                    }}
                />
                {this.state.display_bar_label ?
                    <VictoryBar horizontal
                        labels={({ datum }) => datum.y}
                        label="Time (ms)"
                        style={{ data: { fill: (datum) => this.colorSwitcher(datum) } }}

                        data={this.state.data}
                    />
                    :
                    <VictoryBar horizontal
                        label="Time (ms)"
                        //   style={{ data: { fill: "#33b716" }}}
                        style={{ data: { fill: (datum) => this.colorSwitcher(datum) } }}
                        data={this.state.data}
                    />}

            </VictoryChart>
        )
    }
}
const mapStateToProps = state => ({
    curr_status: state.curr_status,
});

export default connect(mapStateToProps)(Chart);