import React from "react";
import {FormattedMessage} from "react-intl";

const PartnerSection = () => (
  <section id="partenaires" className="partenaires">
    <div className="container" data-aos="fade-up">
      <h2><FormattedMessage id="partners.title" /> </h2>
      <div className="row" data-aos="zoom-in">
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.uqam.ca/" target="_blank">
            <img
              src="assets/img/partenaires/uqam.svg"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.uqo.ca/" target="_blank">
            <img
              src="assets/img/partenaires/uqo.svg"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.hydroquebec.com/" target="_blank">
            <img
              src="assets/img/partenaires/Hydro-Québec_logo.svg"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://frq.gouv.qc.ca/" target="_blank">
            <img
              src="assets/img/partenaires/logo-frqnt-couleur.png"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.nserc-crsng.gc.ca/index_fra.asp" target="_blank">
            <img
              src="assets/img/partenaires/CRSNG_RGB.png"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://boucherville.ca/" target="_blank">
            <img
              src="assets/img/partenaires/VDB_LOGO_HORIZONTAL_NOIR_RGB.png"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.saint-lambert.ca/" target="_blank">
            <img
              src="assets/img/partenaires/logo-saint-lambert.svg"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.ville.varennes.qc.ca/" target="_blank">
            <img
              src="assets/img/partenaires/VV-Logo-Varennes.jpg"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.habitat-nature.com/" target="_blank">
            <img
              src="assets/img/partenaires/habitat-logo.png"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.mitacs.ca/fr" target="_blank">
            <img
              src="assets/img/partenaires/mitacs_transparent.png"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.jakarto.com/fr/" target="_blank">
            <img
              src="assets/img/partenaires/logo_Jakarto_CouleurFR-1.png"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://ripon.ca/" target="_blank">
            <img
              src="assets/img/partenaires/logo-ripon-01-jpg-4.jpg"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.gatineau.ca/" target="_blank">
            <img
              src="assets/img/partenaires/logo_gatineau.png"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://www.laval.ca/" target="_blank">
            <img
              src="assets/img/partenaires/logo-laval.svg"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <a href="https://candiac.ca/" target="_blank">
            <img
              src="assets/img/partenaires/Logo-Candiac.jpg"
              className="img-fluid"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default PartnerSection;
