import React from 'react';
import { FormattedMessage } from 'react-intl';
import Footer from "./Footer";

const TermsConditions = () => {
  return (
    <div>
      <section id="conditions" className="conditions">
        <div className="container" data-aos="fade-up">
          <h2><FormattedMessage id="terms.acceptance_of_terms_title"/></h2>
          <p><strong><FormattedMessage id="terms.acceptance_of_terms_header"/></strong></p>
          <p>
            <FormattedMessage id="terms.acceptance_of_terms_content"/>
          </p><br/>
          <p><strong><FormattedMessage id="terms.use_of_platform_header"/></strong></p>
          <p>
            <FormattedMessage id="terms.use_of_platform_content"/>
          </p><br/>
          <p><strong><FormattedMessage id="terms.accuracy_of_information_header"/></strong></p>
          <p>
            <FormattedMessage id="terms.accuracy_of_information_content"/>
          </p><br/>
          <p><strong><FormattedMessage id="terms.limited_liability_header"/></strong></p>
          <p>
            <FormattedMessage id="terms.limited_liability_content"/>
          </p><br/>
          <p><strong><FormattedMessage id="terms.appropriate_use_header"/></strong></p>
          <p>
            <FormattedMessage id="terms.appropriate_use_content"/>
          </p><br/>
          <p><strong><FormattedMessage id="terms.intellectual_property_header"/></strong></p>
          <p>
            <FormattedMessage id="terms.intellectual_property_content"/>
          </p><br/>
          <p><strong><FormattedMessage id="terms.platform_modifications_header"/></strong></p>
          <p>
            <FormattedMessage id="terms.platform_modifications_content"/>
          </p><br/>
          <p><strong><FormattedMessage id="terms.external_links_header"/></strong></p>
          <p>
            <FormattedMessage id="terms.external_links_content"/>
          </p><br/>
          <p><strong><FormattedMessage id="terms.terms_of_use_modifications_header"/></strong></p>
          <p>
            <FormattedMessage id="terms.terms_of_use_modifications_content"/>
          </p><br/>
          <p><FormattedMessage id="terms.final_acceptance_statement"/></p>
        </div>
        <br/>
        <br/>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default TermsConditions;
