import React, { Component } from 'react';
import { Tab, Header, Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
// import {connect} from 'react-redux';

import Chart from "../components/App/chart";
import Table from "../components/App/Table";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { connect } from 'react-redux';
import SummaryBoxRow from '../components/App/SummaryBoxRow';
// import HeaderMenu from '../header_menu'
// import FooterMenu from '../footer_menu'
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PdfDocument } from "../components/App/SylvPDFTemplate";
import LoadingSpinner from '../components/App/loadingSpinner';
/* in ES 6 */
import domtoimage from 'dom-to-image';
import fileDownload from "js-file-download";
// import htmlToImage from 'html-to-image';
import ReactExport from "react-export-excel";

import { FormattedMessage, injectIntl } from 'react-intl';

const injectGetMessage = (fn) => 
  React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)));

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DefaultCurrStatus = {
  "number_trees": 100,
  "func_groups_list": [
    "5",
    "1A"
  ],
  "count_by_species": {
    "PIGL": 1,
    "PIPU": 5,
    "PODE": 22
  },
  "count_by_genus": {
    "Picea": 6,
    "Populus": 22
  },
  "count_by_family": {
    "Pinaceae": 6,
    "Salicaceae": 22
  },
  "count_by_func_group": {
    "5": 22,
    "1A": 6
  },
  "top_species": {
    "PODE": 22,
    "PIPU": 5,
    "PIGL": 1
  },
  "carbon_storage_by_species": {
    "PIPU": 242.87261242894166,
    "PIGL": 46.74344281807076,
    "PODE": 11724.708866200415
  },
  "value_by_species": {
    "PIPU": 5537.49556337987,
    "PIGL": 1065.7504962520134,
    "PODE": 267323.36214936944
  },
  "species_table": [
    {
      "Species": "PIGL",
      "Number of trees": 1,
      "Carbon storage": 46.74344281807076,
      "Value": 1065.7504962520134
    },
    {
      "Species": "PIPU",
      "Number of trees": 5,
      "Carbon storage": 242.87261242894166,
      "Value": 5537.49556337987
    },
    {
      "Species": "PODE",
      "Number of trees": 22,
      "Carbon storage": 11724.708866200415,
      "Value": 267323.36214936944
    }
  ],
  "func_group_table": [
    {
      "Func Group": "1A",
      "Number of trees": 6,
      "Carbon storage": 289.6160552470124,
      "Value": 6603.246059631883
    },
    {
      "Func Group": "5",
      "Number of trees": 22,
      "Carbon storage": 11724.708866200415,
      "Value": 267323.36214936944
    }
  ],
  "genus_table": [
    {
      "Genus": "Picea",
      "Number of trees": 6,
      "Carbon storage": 289.6160552470124,
      "Value": 6603.246059631883
    },
    {
      "Genus": "Populus",
      "Number of trees": 22,
      "Carbon storage": 11724.708866200415,
      "Value": 267323.36214936944
    }
  ],
  "family_table": [
    {
      "Family": "Pinaceae",
      "Number of trees": 6,
      "Carbon storage": 289.6160552470124,
      "Value": 6603.246059631883
    },
    {
      "Family": "Salicaceae",
      "Number of trees": 22,
      "Carbon storage": 11724.708866200415,
      "Value": 267323.36214936944
    }
  ],
  "different_species": 3,
  "species_list": [
    "Picea pungens",
    "Picea glauca",
    "Populus deltoides"
  ],
  "different_genus": 2,
  "genus_list": [
    "Picea",
    "Populus"
  ],
  "different_families": 2,
  "families": [
    "Pinaceae",
    "Salicaceae"
  ],
  "species_diversity": 1.8517454293137185,
  "group_diversity": 1.6813210759468074,
  "species_richness": 3,
  "group_richness": 2,
  "total_carbon_storage": 12014.324921447429,
  "total_value": 273926.60820900137
};

class NewStatus extends Component {

  constructor(props) {
    super(props);
    this.top_species_old = React.createRef();
    this.top_species_new = React.createRef();
    this.count_by_species_old = React.createRef();
    this.count_by_species_new = React.createRef();
    this.count_by_func_group_old = React.createRef();
    this.count_by_func_group_new = React.createRef();
    this.count_by_genus_old = React.createRef();
    this.count_by_genus_new = React.createRef();
    this.count_by_family_old = React.createRef();
    this.count_by_family_new = React.createRef();
    this.carbon_storage_by_species_old = React.createRef();
    this.carbon_storage_by_species_new = React.createRef();
    this.value_by_species_old = React.createRef();
    this.value_by_species_new = React.createRef();
    this.state = {
      chartsRef: [],
      graphs: {}
    }
  }

  componentDidMount() {
    setTimeout(function () { //Start the timer
      // console.log(this.count_by_species_old.current)
      this.htmlChartToPng(this.top_species_old.current, 'top_species_old')
      this.htmlChartToPng(this.top_species_new.current, 'top_species_new')
      this.htmlChartToPng(this.count_by_species_old.current, 'count_by_species_old')
      this.htmlChartToPng(this.count_by_species_new.current, 'count_by_species_new')
      this.htmlChartToPng(this.count_by_func_group_old.current, 'count_by_func_group_old')
      this.htmlChartToPng(this.count_by_func_group_new.current, 'count_by_func_group_new')
      this.htmlChartToPng(this.count_by_genus_old.current, 'count_by_genus_old')
      this.htmlChartToPng(this.count_by_genus_new.current, 'count_by_genus_new')
      this.htmlChartToPng(this.count_by_family_old.current, 'count_by_family_old')
      this.htmlChartToPng(this.count_by_family_new.current, 'count_by_family_new')
      this.htmlChartToPng(this.carbon_storage_by_species_old.current, 'carbon_storage_by_species_old')
      this.htmlChartToPng(this.carbon_storage_by_species_new.current, 'carbon_storage_by_species_new')
      this.htmlChartToPng(this.value_by_species_old.current, 'value_by_species_old')
      this.htmlChartToPng(this.value_by_species_new.current, 'value_by_species_new')
      // this.chartToPng(document.getElementById('node-to-convert2'))
    }.bind(this), 5000)
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate')
  }

  doWork(text, data) {
    // console.log(text, data);
    // var obj = {[text]:data}
    const { graphs, newKey, newValue } = this.state;
    const newPair = { [text]: data };

    this.setState(
      {
        graphs: { ...graphs, ...newPair }
      }

    );

  }


  htmlChartToPng(nodeRef, key_name) {
    // if (nodeRef) {
    //   htmlToImage.toPng(nodeRef)
    //     .then(this.doWork.bind(this, key_name))
    //     .catch(function (error) {
    //       console.error('oops, something went wrong!', error);
    //     }.bind(this));
    // }
  }

  getFileName() {
    let d = new Date();
    let dformat = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}-${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
    // console.log(dformat)
    // var newDate = new Date();
    // let dformat = parseInt(newDate.getMonth()+1)+`-`+newDate.getDate()+`-`+newDate.getFullYear()+`-`+newDate.getTime()
    console.log(dformat)
    return "SylvCiT_report_" + dformat
}

  render() {
    // const {new_status} = this.state;
    const { prediction } = this.props;

    // if(new_status!==undefined)
    // {
    //     console.log('New status value',new_status)
    // }
    // console.log('render new_status', this.props, this.state);
    // console.log('new_status', prediction)
    // console.log(this.props.curr_status)
    let curr_status;
    if (this.props.curr_status !== undefined) {
      curr_status = this.props.curr_status
    }
    else {
      curr_status = DefaultCurrStatus
    }
    let info_new_status;
    if (this.props.new_status.info_status.info_prediction !== undefined) {
      info_new_status = this.props.new_status.info_status.info_prediction
      console.log(info_new_status)
    }
    else {
      info_new_status = DefaultCurrStatus
    }

    var data;
    if (curr_status !== undefined) {
      data = [
        {
          name: "Groupes fonctionnels", content: (
            <Container fluid={true}>
              <Row>
                <Col className="border-right">
                  <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
                  <div>
                  {injectGetMessage((getMessage) => <Chart data={curr_status.count_by_func_group} display_bar_label={true} 
                                    title={getMessage({ id: 'status.nb_trees_func_group' })} xLabel={getMessage({ id: 'func_group_abr' })} yLabel={getMessage({ id: 'home.sel_nb_trees' })}/>)}
                    {/* <FormattedMessage id="status.nb_trees_func_group">
                      {(title) =>
                        <Chart data={curr_status.count_by_func_group} title={title} />
                      }
                    </FormattedMessage> */}
                    <Table data={curr_status.func_group_table} />
                  </div>
                </Col>
                <Col>
                  <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
                  <div>
                  {injectGetMessage((getMessage) => <Chart data={info_new_status.count_by_func_group} display_bar_label={true} 
                                    title={getMessage({ id: 'status.nb_trees_func_group' })} xLabel={getMessage({ id: 'func_group_abr' })} yLabel={getMessage({ id: 'home.sel_nb_trees' })}/>)}
                    {/* <FormattedMessage id="status.nb_trees_func_group">
                      {(title) =>
                        <Chart data={info_new_status.count_by_func_group} title={title} />
                      }
                    </FormattedMessage> */}
                    <Table data={info_new_status.func_group_table} />
                  </div>
                </Col>
              </Row>
            </Container>
          )

        },
        {
          name: "Règle 10-20-30", content: (
            <Container fluid={true}>
              <Row>
                <Col>
                  <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
                  <Row>

                    <Col>
                    {injectGetMessage((getMessage) => <Chart data={curr_status.percentage_by_species} show_bar={true} y_bar={10} display_bar_label={true} fixOverlap={false} 
                    title={getMessage({ id: 'status.prop_spec' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'Percentage' })}/>)}

                    </Col>
                    <Col>
                      {injectGetMessage((getMessage) => <Chart data={curr_status.percentage_by_genus} show_bar={true} y_bar={10} display_bar_label={true} fixOverlap={false}
                       title={getMessage({ id: 'status.prop_genus' })} xLabel={getMessage({ id: 'genus' })} yLabel={getMessage({ id: 'Percentage' })}/>)}
                    </Col>
                  </Row>
                  <Row>
                  {injectGetMessage((getMessage) => <Chart data={curr_status.percentage_by_family} show_bar={true} y_bar={30} display_bar_label={true} fixOverlap={false} 
                  title={getMessage({ id: 'status.prop_family' })} xLabel={getMessage({ id: 'family' })} yLabel={getMessage({ id: 'Percentage' })}/>)}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
                  <Row>

<Col>
{injectGetMessage((getMessage) => <Chart data={info_new_status.percentage_by_species} show_bar={true} y_bar={10} display_bar_label={true} fixOverlap={false} 
title={getMessage({ id: 'status.prop_spec' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'Percentage' })}/>)}

</Col>
<Col>
  {injectGetMessage((getMessage) => <Chart data={info_new_status.percentage_by_genus} show_bar={true} y_bar={20} display_bar_label={true} fixOverlap={false}
   title={getMessage({ id: 'status.prop_genus' })} xLabel={getMessage({ id: 'genus' })} yLabel={getMessage({ id: 'Percentage' })}/>)}
</Col>
</Row>
<Row>
{injectGetMessage((getMessage) => <Chart data={info_new_status.percentage_by_family} show_bar={true} y_bar={30} display_bar_label={true} fixOverlap={false} 
title={getMessage({ id: 'status.prop_family' })} xLabel={getMessage({ id: 'family' })} yLabel={getMessage({ id: 'Percentage' })}/>)}
</Row>

                </Col>
              </Row>
            </Container>

          )
        },
        {
          name: "Espèces", content: (
            <Container fluid={true}>

              <Row>
                <Col className="border-right">
                  <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
                  <div>
                    <SummaryBoxRow
                      dataBox1={curr_status.number_trees}
                      titleBox1={<FormattedMessage id="status.nb_trees" />}
                      dataBox2={curr_status.species_richness}
                      titleBox2={<FormattedMessage id="status.sp_richness" />}
                      dataBox3={curr_status.species_diversity.toFixed(2)}
                      titleBox3={<FormattedMessage id="status.sp_diversity" />}
                    />
                    <span>&nbsp;</span>

                    <Table data={curr_status.species_table} />
                    <div id="node-to-convert" className="graph_container" ref={this.top_species_old}>
                      {injectGetMessage((getMessage) => <Chart id="top_species_chart" data={curr_status.top_species} display_bar_label={true} 
                                     title={getMessage({ id: 'status.sp_top' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'Nb d\'arbres' })}/>)}
                    </div>
                    {/* <div style={{ width: '500px', height: '350px', overflow: 'hidden' }}>
                      <div id="node-to-convert" className="graph_container" ref={this.top_species_old}>
                        <Chart id="top_species_chart" data={curr_status.top_species} title={'Top species'} />
                      </div>
                      <div className="graph_container" ref={this.count_by_species_old}>
                        <Chart data={curr_status.count_by_species} title={'Nb trees by species'} />
                      </div>
                      <div className="graph_container" ref={this.count_by_species_new}>
                        <Chart data={info_new_status.count_by_species} title={'Nb trees by species'} />
                      </div>
                      <div className="graph_container" ref={this.count_by_func_group_old}>
                        <Chart data={curr_status.count_by_func_group} title={'Nb trees by func group'} />
                      </div>
                      <div className="graph_container" ref={this.count_by_func_group_new}>
                        <Chart data={info_new_status.count_by_func_group} title={'Nb trees by func group'} />
                      </div>
                      <div className="graph_container" ref={this.count_by_genus_old}>
                        <Chart data={curr_status.count_by_genus} title={'Nb trees by genus'} />
                      </div>
                      <div className="graph_container" ref={this.count_by_genus_new}>
                        <Chart data={info_new_status.count_by_genus} title={'Nb trees by genus'} />
                      </div>
                      <div className="graph_container" ref={this.count_by_family_old}>
                        <Chart data={curr_status.count_by_family} title={'Nb trees by family'} />
                      </div>
                      <div className="graph_container" ref={this.count_by_family_new}>
                        <Chart data={info_new_status.count_by_family} title={'Nb trees by family'} />
                      </div>
                      <div className="graph_container" ref={this.carbon_storage_by_species_old}>
                        <Chart data={curr_status.carbon_storage_by_species} title={'Carbon storage by species'} />
                      </div>
                      <div className="graph_container" ref={this.carbon_storage_by_species_new}>
                        <Chart data={info_new_status.carbon_storage_by_species} title={'Carbon storage by species'} />
                      </div>
                      <div className="graph_container" ref={this.value_by_species_old}>
                        <Chart data={curr_status.value_by_species} title={'Value by species'} />
                      </div>
                      <div className="graph_container" ref={this.value_by_species_new}>
                        <Chart data={info_new_status.value_by_species} title={'Value by species'} />
                      </div>
                    </div> */}
                  </div>
                </Col>
                <Col>
                  <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
                  <div>
                    <SummaryBoxRow
                      dataBox1={info_new_status.number_trees}
                      titleBox1={<FormattedMessage id="status.nb_trees" />}
                      dataBox2={info_new_status.species_richness}
                      titleBox2={<FormattedMessage id="status.sp_richness" />}
                      dataBox3={info_new_status.species_diversity.toFixed(2)}
                      titleBox3={<FormattedMessage id="status.sp_diversity" />}
                    />
                    <span>&nbsp;</span>
                    <Table data={info_new_status.species_table} />
                    <div id="node-to-convert" className="graph_container" ref={this.top_species_new}>

                      {injectGetMessage((getMessage) => <Chart id="top_species_chart" data={info_new_status.top_species} display_bar_label={true} 
                                     title={getMessage({ id: 'status.sp_top' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'Nb d\'arbres' })}/>)}
                    </div>

                  </div>

                </Col>
              </Row>
            </Container>
          )
        },
        // {
        //   name: "Espèces", content: (
        //     <Container fluid={true}>
        //       <Row>
        //         <Col className="border-right">
        //           <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
        //           <div>
        //             <SummaryBoxRow
        //               dataBox1={curr_status.species_richness}
        //               titleBox1={<FormattedMessage id="status.sp_richness" />}
        //               dataBox2={curr_status.species_diversity.toFixed(2)}
        //               titleBox2={<FormattedMessage id="status.sp_diversity" />}
        //             />
        //             <span>&nbsp;</span>

        //             <div style={{ width: '500px', height: '350px', overflow: 'hidden' }}>
        //               <div id="node-to-convert2" className="graph_container" ref={this.node_treesspecies}>
        //                 <FormattedMessage id="status.nb_trees_sp">
        //                   {(title) =>
        //                     <Chart data={curr_status.count_by_species} title={title} />
        //                   }
        //                 </FormattedMessage>
        //               </div>
        //             </div>


        //           </div>
        //         </Col>
        //         <Col>
        //           <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
        //           <div>
        //             <SummaryBoxRow
        //               dataBox1={info_new_status.species_richness}
        //               titleBox1={<FormattedMessage id="status.sp_richness" />}
        //               dataBox2={info_new_status.species_diversity.toFixed(2)}
        //               titleBox2={<FormattedMessage id="status.sp_diversity" />}
        //             />
        //             <span>&nbsp;</span>
        //             <FormattedMessage id="status.nb_trees_sp">
        //               {(title) =>
        //                 <Chart data={info_new_status.count_by_species} title={title} />
        //               }
        //             </FormattedMessage>

        //           </div>
        //         </Col>
        //       </Row>
        //     </Container>

        //   )
        // },


        // {
        //   name: "Genus", content: (
        //     <Container fluid={true}>
        //       <Row>
        //         <Col className="border-right">
        //           <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
        //           <div>
        //           <FormattedMessage id="status.nb_trees_genus">
        //                                     {(title) =>
        //                                         <Chart data={curr_status.count_by_genus} title={title} />
        //                                     }
        //                                 </FormattedMessage>
        //             <Table data={curr_status.genus_table} />
        //           </div>
        //         </Col>
        //         <Col>
        //           <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
        //           <div>
        //           <FormattedMessage id="status.nb_trees_genus">
        //                                     {(title) =>
        //                                         <Chart data={info_new_status.count_by_genus} title={title} />
        //                                     }
        //                                 </FormattedMessage>
        //             <Table data={info_new_status.genus_table} />
        //           </div>
        //         </Col>
        //       </Row>
        //     </Container>
        //   )
        // },
        // {
        //   name: "Family", content: (
        //     <Container fluid={true}>
        //       <Row>
        //         <Col className="border-right">
        //           <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
        //           <div>
        //           <FormattedMessage id="status.nb_trees_family">
        //                                     {(title) =>
        //                                         <Chart data={curr_status.count_by_family} title={title} />
        //                                     }
        //                                 </FormattedMessage>
        //             <Table data={curr_status.family_table} />
        //           </div>
        //         </Col>
        //         <Col>
        //           <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
        //           <div>
        //           <FormattedMessage id="status.nb_trees_family">
        //                                     {(title) =>
        //                                         <Chart data={info_new_status.count_by_family} title={title} />
        //                                     }
        //                                 </FormattedMessage>
        //             <Table data={info_new_status.family_table} />
        //           </div>
        //         </Col>
        //       </Row>
        //     </Container>
        //   )
        // },
        // {
        //   name: "Diversity", content: (
        //     <Container fluid={true}>
        //       <Row>
        //         <Col className="border-right">
        //           <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
        //           <div>
        //                         <SummaryBoxRow
        //                         dataBox1={curr_status.number_trees}
        //                         titleBox1={<FormattedMessage id="status.nb_trees" />}
        //                         dataBox2={curr_status.group_diversity.toFixed(2)}
        //                         titleBox2={<FormattedMessage id="status.function_group_diversity" />}
        //                         dataBox3={curr_status.species_diversity.toFixed(2)}
        //                         titleBox3={<FormattedMessage id="status.sp_diversity" />}
        //                     />
        //                     <span>&nbsp;</span>
        //                     <FormattedMessage id="status.nb_trees_sp">
        //                                     {(title) =>
        //                                         <Chart data={curr_status.count_by_species} title={title} />
        //                                     }
        //                                 </FormattedMessage>
        //           </div>
        //         </Col>
        //         <Col>
        //           <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
        //           <div>
        //                         <SummaryBoxRow
        //                         dataBox1={info_new_status.number_trees}
        //                         titleBox1={<FormattedMessage id="status.nb_trees" />}
        //                         dataBox2={info_new_status.group_diversity.toFixed(2)}
        //                         titleBox2={<FormattedMessage id="status.function_group_diversity" />}
        //                         dataBox3={info_new_status.species_diversity.toFixed(2)}
        //                         titleBox3={<FormattedMessage id="status.sp_diversity" />}
        //                     />
        //                     <span>&nbsp;</span>
        //                     <FormattedMessage id="status.nb_trees_sp">
        //                                     {(title) =>
        //                                         <Chart data={info_new_status.count_by_species} title={title} />
        //                                     }
        //                                 </FormattedMessage>
        //           </div>
        //         </Col>
        //       </Row>
        //     </Container>
        //   )
        // },
        // {
        //   name: "Richness", content: (
        //     <Container fluid={true}>
        //       <Row>
        //         <Col className="border-right">
        //           <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
        //           <div>
        //           <SummaryBoxRow
        //                         dataBox1={curr_status.number_trees}
        //                         titleBox1={<FormattedMessage id="status.nb_trees" />}
        //                         dataBox2={curr_status.group_richness}
        //                         titleBox2={<FormattedMessage id="status.function_group_richness" />}
        //                         dataBox3={curr_status.species_richness}
        //                         titleBox3={<FormattedMessage id="status.sp_richness" />}
        //                     />
        //                     <span>&nbsp;</span>
        //                     <FormattedMessage id="status.nb_trees_func_group">
        //                             {(title) =>
        //                                 <Chart data={curr_status.count_by_func_group} title={title} />
        //                             }
        //                         </FormattedMessage>
        //           </div>
        //         </Col>
        //         <Col>
        //           <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
        //           <div>
        //           <SummaryBoxRow
        //                         dataBox1={info_new_status.number_trees}
        //                         titleBox1={<FormattedMessage id="status.nb_trees" />}
        //                         dataBox2={info_new_status.group_richness}
        //                         titleBox2={<FormattedMessage id="status.function_group_richness" />}
        //                         dataBox3={info_new_status.species_richness}
        //                         titleBox3={<FormattedMessage id="status.sp_richness" />}
        //                     />
        //                     <span>&nbsp;</span>
        //                     <FormattedMessage id="status.nb_trees_func_group">
        //                             {(title) =>
        //                                 <Chart data={info_new_status.count_by_func_group} title={title} />
        //                             }
        //                         </FormattedMessage>
        //           </div>
        //         </Col>
        //       </Row>
        //     </Container>
        //   )
        // },
        {
          name: "Stockage et valeur du coût social du carbone", content: (
            <Container fluid={true}>
              <Row>
                <Col className="border-right">
                  <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
                  <div>
                    <SummaryBoxRow
                      dataBox1={curr_status.number_trees}
                      titleBox1={<FormattedMessage id="status.nb_trees" />}
                      dataBox2={curr_status.total_carbon_storage.toFixed(2)}
                      titleBox2={<FormattedMessage id="status.tot_carb" />}
                      dataBox3={(curr_status.total_value).toFixed(2)}
                      titleBox3={<FormattedMessage id="status.total_val" />}
                    />
                    <span>&nbsp;</span>
                    {injectGetMessage((getMessage) =>  <Chart data={curr_status.carbon_storage_by_species}
                                     title={getMessage({ id: 'status.carb_stor_sp' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'val' })}/>)}
                  </div>
                </Col>
                <Col>
                  <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
                  <div>
                    <SummaryBoxRow
                      dataBox1={info_new_status.number_trees}
                      titleBox1={<FormattedMessage id="status.nb_trees" />}
                      dataBox2={info_new_status.total_carbon_storage.toFixed(2)}
                      titleBox2={<FormattedMessage id="status.tot_carb" />}
                      dataBox3={(info_new_status.total_value).toFixed(2)}
                      titleBox3={<FormattedMessage id="status.total_val" />}
                    />
                    <span>&nbsp;</span>
                    {injectGetMessage((getMessage) =>  <Chart data={info_new_status.carbon_storage_by_species}
                                     title={getMessage({ id: 'status.carb_stor_sp' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'val' })}/>)}
                  </div>
                </Col>
              </Row>
              <Row>
              <Col className="border-right">
                    {injectGetMessage((getMessage) =>  <Chart data={curr_status.value_by_species} 
                                     title={getMessage({ id: 'status.val_sp' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'val' })}/>)}
              </Col>
              <Col>
                    {injectGetMessage((getMessage) =>  <Chart data={info_new_status.value_by_species} 
                                     title={getMessage({ id: 'status.val_sp' })} xLabel={getMessage({ id: 'Species' })} yLabel={getMessage({ id: 'val' })}/>)}
              </Col>
              </Row>

            </Container>
          )
        },
        // {
        //   name: "Valeur du coût social du carbone", content: (
        //     <Container fluid={true}>
        //       <Row>
        //         <Col className="border-right">
        //           <Header textAlign='center' as='h2'><FormattedMessage id="before" /></Header>
        //           <div>
        //             <SummaryBoxRow
        //               dataBox1={curr_status.number_trees}
        //               titleBox1={<FormattedMessage id="status.nb_trees" />}
        //               dataBox2={(curr_status.total_value).toFixed(2)}
        //               titleBox2={<FormattedMessage id="status.total_val" />}
        //             />
        //             <span>&nbsp;</span>
        //             <FormattedMessage id="status.val_sp">
        //               {(title) =>
        //                 <Chart data={curr_status.value_by_species} title={title} />
        //               }
        //             </FormattedMessage>
        //           </div>
        //         </Col>
        //         <Col>
        //           <Header textAlign='center' as='h2'><FormattedMessage id="after" /></Header>
        //           <div>
        //             <SummaryBoxRow
        //               dataBox1={info_new_status.number_trees}
        //               titleBox1={<FormattedMessage id="status.nb_trees" />}
        //               dataBox2={(info_new_status.total_value).toFixed(2)}
        //               titleBox2={<FormattedMessage id="status.total_val" />}
        //             />
        //             <span>&nbsp;</span>
        //             <FormattedMessage id="status.val_sp">
        //               {(title) =>
        //                 <Chart data={info_new_status.value_by_species} title={title} />
        //               }
        //             </FormattedMessage>

        //           </div>
        //         </Col>
        //       </Row>
        //     </Container>
        //   )
        // },
      ];
    }
    else {
      data = [

        // { name: "Info. générales", content: <LoadingSpinner /> },
        { name: <FormattedMessage id="status.species" />, content: <LoadingSpinner /> },
        { name: <FormattedMessage id="status.santamour" />, content: <LoadingSpinner /> },
        { name: <FormattedMessage id="status.func_groups" />, content: <LoadingSpinner /> },
        { name: <FormattedMessage id="status.carbon_storage" />, content: <LoadingSpinner /> },
        { name: "Stockage et valeur du coût social du carbone", content: <LoadingSpinner /> },
        // { name: "Valeur", content: <LoadingSpinner /> },
    ];
    }
    const panes = data.map(d => ({
      menuItem: d.name,
      render: () => <Tab.Pane> {d.content}</Tab.Pane>
    }));
    return (
      <React.Fragment >
        {/* <HeaderMenu /> */}
        <div>
          {/* Breadcrumb navigation to navigate between pages*/}
          <div className="ui breadcrumb">
            <Link to="/"><FormattedMessage id="status.sel_area" /></Link>
            <div className="divider"> /</div>
            <Link to="/status"><FormattedMessage id="status.curr_status" /></Link>
            <div className="divider"> /</div>
            <Link to="/settings"><FormattedMessage id="settings.sim_plantation" /></Link>
            <div className="divider"> /</div>
            <Link to="/selectgroup"><FormattedMessage id="groups.sel_func_group" /></Link>
            <div className="divider"> /</div>
            <Link to="/selectspecies"><FormattedMessage id="species.select_sp" /></Link>
            <div className="divider"> /</div>
            <div className="active section"><FormattedMessage id="new_stat.new" /></div>
          </div>


          <Header textAlign='center' as='h2'><FormattedMessage id="new_stat.status_after" /></Header>

          {/* Tab Pane */}
          <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />

          {/* Grid for positioning two buttons */}


          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column textAlign='right' width='8'>
                <Button className="green ui button" href='/app' block="false" ><FormattedMessage id="new_stat.new_sim" /></Button>
              </Grid.Column>
              <Grid.Column textAlign='left' width='8'>
                                {curr_status !== undefined && info_new_status !== undefined &&
                                    <ExcelFile filename={this.getFileName()} element={<Button className="green ui button" block="false" ><FormattedMessage id="download_report" /></Button>}>
                                        <ExcelSheet data={curr_status.species_table} name="species_before">
                                            {Object.keys(curr_status.species_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={info_new_status.species_table} name="species_after">
                                            {Object.keys(info_new_status.species_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={curr_status.genus_table} name="genus_before">
                                            {Object.keys(curr_status.genus_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={info_new_status.genus_table} name="genus_after">
                                            {Object.keys(info_new_status.genus_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={curr_status.family_table} name="families_before">
                                            {Object.keys(curr_status.family_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={info_new_status.family_table} name="families_after">
                                            {Object.keys(info_new_status.family_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={curr_status.func_group_table} name="functional_groups_before">
                                            {Object.keys(curr_status.func_group_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={info_new_status.func_group_table} name="functional_groups_after">
                                            {Object.keys(info_new_status.func_group_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={curr_status.carbon_table} name="carbon_storage_before">
                                            {Object.keys(curr_status.carbon_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                        <ExcelSheet data={info_new_status.carbon_table} name="carbon_storage_after">
                                            {Object.keys(info_new_status.carbon_table[0]).map((item) => (
                                                // console.log(item)
                                                <ExcelColumn label={String(item)} value={String(item)} key={item} />
                                            ))}
                                        </ExcelSheet>
                                    </ExcelFile>
                                }
                            </Grid.Column>
            </Grid.Row>
          </Grid>

        </div>
        {/* <FooterMenu/>   */}
      </React.Fragment>
    );

  }

}

/*const mapStateToProps = state => ({
    curr_status: state.curr_status,
});

export default connect(mapStateToProps)(NewStatus);*/
// export default NewStatus;

const mapStateToProps = state => ({
  curr_status: state.curr_status,
  new_status: state.new_status,
  ranking: state.ranking,
  groups: state.groups,
  species_list: state.species_list,
  nb_trees_plant: state.nb_trees_plant
});

export default connect(mapStateToProps)(NewStatus);