import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home"
import Habitat from "./pages/Habitat"
// import TreeMap from "./components/map"
import Status from "./pages/Status"
import NewStatus from "./pages/NewStatus"
import Settings from "./pages/Settings"
import SelectGroup from "./pages/SelectGroup"
import SelectSpecies from "./pages/SelectSpecies"
import HomePage from "./components/HomePage/HomePage";
import MediaDetail from "./components/HomePage/MediaDetail";

import {
  Container,
  Select,
  Image,
  Menu,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';

import { IntlProvider } from "react-intl";

import en from "./locale/en.json";
import fr from "./locale/fr.json";
import UserGuideLink from "./components/App/UserGuideLink";
import Header from "./components/HomePage/Header";
import {Helmet} from "react-helmet";
import TermsConditions from "./components/HomePage/TermsConditions";
import AOS from "aos";
import mediadetail from "./components/HomePage/MediaDetail";

export default function inApp() {
  const [locale, setLocale] = useState("fr");

  const messages = { en, fr };

  const handleChange = (event, data)  => {
    console.log(event)
    console.log(data.value);
    setLocale(data.value);
  };


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>SylvCiT - Outil de recherche en foresterie urbaine</title>
        <meta
          content="SylvCiT - Outil de recherche en foresterie urbaine"
          name="description"
        />
        <meta content="SylvCiT foresterie urbaine r\xE9silience" name="keywords" />
        <link
          href="assets/vendor/bootstrap/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link href="assets/img/favicon.png" rel="icon" />
        <link href="assets/img/apple-touch-icon.png" rel="apple-touch-icon" />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Nunito:300,300i,400,400i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/remixicon/remixicon.css" rel="stylesheet" />
        <link href="assets/css/style.css" rel="stylesheet" />
      </Helmet>

      <div >
        <Header onLanguageChange={handleChange}></Header>
      </div>

      <div style={{margin: "10px 10px 10px 10px"}}>
        <Routes>
          <Route path="/"  element={<HomePage />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/app/"  element={<Home />} />
          <Route path="/app/habitat" exact element={<Habitat />} />
          <Route path="/app/status" exact element={<Status />} />
          <Route path="/app/newstatus" exact element={<NewStatus />} />
          <Route path="/app/settings" exact element={<Settings />} />
          <Route path="/app/selectgroup" exact element={<SelectGroup />} />
          <Route path="/app/selectspecies" exact element={<SelectSpecies />} />
          <Route path="/medias" exact element={<MediaDetail />} />
        </Routes>
      </div>
    </IntlProvider>
  );
}
