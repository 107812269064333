import React, { Component } from "react";

// Import React Table
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';

class Table extends Component {
  constructor(props) {
    super(props);
    this.getKeys = this.getKeys.bind(this);
    this.state = {
      data: [],
      headers: []
    };
  }

  getKeys = function () {
    return Object.keys(this.props.data[0]);
  }


  UcFirstAndReplace(string) {
    return (string.charAt(0).toUpperCase() + string.slice(1)).replace(/_|-|\./g, ' ');
  }
  componentDidMount() {
    console.log('component did mount')

    console.log(this.props.data)
    let data_to_visualize = this.props.data
    let firstObject = data_to_visualize[0]
    let firstObjectKeys = Object.keys(firstObject)
    let cols = []
    for (var j in firstObjectKeys) {
      let header = {
        // Header: <FormattedMessage id={this.UcFirstAndReplace(firstObjectKeys[j])} />,
        Header: <FormattedMessage id={firstObjectKeys[j]} />,
        accessor: firstObjectKeys[j]
      }
      cols.push(header)
    }
    this.setState({ data: data_to_visualize })
    this.setState({ headers: cols })
  }

  //good place to do network requests when the component has been updated
  componentDidUpdate(prevProps, prevState) {
    console.log('component did update')


    // Typical usage (don't forget to compare props):
    if (this.props.data !== prevProps.data) {
      let data_to_visualize = this.props.data
      let firstObject = data_to_visualize[0]
      let firstObjectKeys = Object.keys(firstObject)
      let cols = []
      for (var j in firstObjectKeys) {
        let header = {
          // Header: this.UcFirstAndReplace(firstObjectKeys[j]),
          Header: <FormattedMessage id={firstObjectKeys[j]} />,
          accessor: firstObjectKeys[j]
        }
        cols.push(header)
      }
      this.setState({ data: data_to_visualize })
      this.setState({ headers: cols })
    }
  }

  render() {
    const { data, headers } = this.state;
    console.log(data)
    return (
      <div style={{ width: '100%' }}>
        <ReactTable
          data={data}
          columns={headers}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  curr_status: state.curr_status,
});
export default connect(mapStateToProps)(Table);