import React from 'react';

import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from "react-intl";

const UserGuideLink = () => {
    const intl = useIntl();
    const url = intl.formatMessage({id: "guide_url"});
    return(
        <Link to={{ pathname: url }} target="_blank"><FormattedMessage id="home.user_guide" /></Link>
    )
};

export default UserGuideLink;