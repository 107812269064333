import React from 'react';
import {createRoot} from "react-dom/client";
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import "semantic-ui-css/semantic.min.css"
import registerServiceWorker from './services/registerServiceWorker';
import reducer from './reducers/reducers';


const store = createStore(reducer);
const root = createRoot(document.getElementById('root'));
const nodeRef = React.createRef();

root.render(
  <BrowserRouter nodeRef={nodeRef}>
    <Provider store={store} nodeRef={nodeRef} >
      <App />
    </Provider>
  </BrowserRouter>
);

registerServiceWorker();
